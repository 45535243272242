<template>

  <div v-if="loading" class="loading-overlay">
    <div class="dot"></div>
    <span class="text">
      Loading
    </span>
  </div>

  <div v-else>
    <AdminHeader />

    <div class="breadcrumb-main px-4">
      <h4 class="text-capitalize breadcrumb-title">Chỉnh giờ</h4>
      <div class="breadcrumb-action justify-content-center flex-wrap">
         <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
               <li class="breadcrumb-item"><a href="/dashboard"><i class="uil uil-estate"></i>Trang chủ</a></li>
               <li class="breadcrumb-item active" aria-current="page">Chỉnh giờ</li>
            </ol>
         </nav>
      </div>
   </div>

    <main class="main-content">
        <div class="container-fluid">
          <div class="row ">
            <div class="col-lg-12">
              <div class="row">
                <div class="col-lg-12 mb-10">

                  <div class="card">
                    <div class="dm-collapse">
                      <div class="dm-collapse-item">
                        <div class="dm-collapse-item__header">
                          <a 
                            class="item-link" 
                            data-bs-toggle="collapse" 
                            data-bs-target="#collapse-body-1" 
                            aria-expanded="false"
                            @click="toggleIcon(1)"
                          >
                            <i :class="{'la la-angle-right': !isOpen[1], 'la la-angle-down': isOpen[1]}"></i>
                            <h6>Tạo sản phẩm</h6>
                          </a>
                        </div>
                        <div v-show="isOpen[1]" class="dm-collapse-item__body">
                          <div class="collapse-body-text">

                            <div class="row">
                              <div class="col-md-12 my-2">
                                <label class="block w-24 text-sm font-medium text-gray-700 dark:text-white">Tên Sản Phẩm</label>
                                <input type="text" class="form-control ih-medium ip-gray radius-xs b-light px-15"
                                  v-model="temporary.product.name"
                                >
      
                                <div class="dm-button-list d-flex flex-wrap my-2">
                                  <button
                                    @click="handleCreateProduct"
                                    type="button"
                                    class="btn btn-success btn-default btn-squared px-3 py-2"
                                    >
                                    Lưu sản phẩm
                                  </button>
                                </div>
                                
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="dm-collapse">
                      <div class="dm-collapse-item">
                        <div class="dm-collapse-item__header">
                          <a 
                            class="item-link" 
                            data-bs-toggle="collapse" 
                            data-bs-target="#collapse-body-1" 
                            aria-expanded="false"
                            @click="toggleIcon(2)"
                          >
                            <i :class="{'la la-angle-right': !isOpen[1], 'la la-angle-down': isOpen[2]}"></i>
                            <h6>Sửa thông tin sản phẩm</h6>
                          </a>
                        </div>
                        <div v-show="isOpen[2]" class="dm-collapse-item__body">
                          <div class="collapse-body-text">

                            <div class="row">
                              <div class="col-12">
                                <label v-for="(p, index_product_information) in products_information.products" :key="index_product_information"
                                    @click="loadProductInformation(p)"
                                    class="d-flex mx-2">
                                     <input type="radio" name="pricing_plan" :value="1" class="me-2">
                                     <span class="text-capitalize">{{ p.name }}</span>
                                  </label>
                              </div>

                              <div class="col-12" v-if="products_information.infor.id">
                                <p>Ảnh Slider trang chủ</p>
                                <label for="file-input-0" style="position: relative; cursor: pointer; width: 100px; height: 125px; border: 1px solid rgb(206, 212, 218);">
                                  <img v-if="products_information.infor.information.avatar" :src="products_information.infor.information.avatar" class="img-fluid" style="cursor: pointer; width: 100%; height: 100%;">
                                  <span style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); cursor: pointer;">
                                    <template v-if="temporary.avatar_uploading">Uploading...</template>
                                    <template v-if="products_information.infor.information.avatar"></template>
                                    <template v-else><span class="badge bg-success">+</span></template>
                                  </span>
                                  <span style="z-index: 1;">
                                    <input accept="image/*" type="file" id="file-input-0" class="form-control" style="position: absolute; top: 0px; left: 0px; width: 100%; height: 100%; opacity: 0;"
                                    @change="handleFileUploadMain($event)" />
                                  </span>
                                </label>
                                
                          
                              </div>

                              <div class="col-12" v-if="products_information.infor.id">
                                <p>Danh sách ảnh chi tiết sản phẩm</p>
                                <button 
                                    class="btn btn-primary btn-default btn-squared btn-shadow-primary"
                                    type="button"
                                    @click="handleAddImageInformation"
                                    >
                                    + IMG
                                  </button>

                                  <table>
                                    <tbody>
                                      <tr>
                                        <td>
                                          <div v-for="(img, idximg) in products_information.infor?.information?.imgs"
                                            :key="idximg"
                                            class="py-2"
                                          >


                                            <div class="" style="position: relative; width: 100px; height: 125px;">
                                              <label style="cursor: pointer; width: 100px; height: 125px; border: 1px solid rgb(206, 212, 218); align-items: center; justify-content: center;">
                                                <img v-if="img.url" :src="img.url" class="img-fluid" style="position: absolute; cursor: pointer; width: 100%; height: 100%; object-fit: cover; z-index: 1;">
                                                
                                                <span 
                                                  style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); cursor: pointer; z-index: 2;">
                                                  <template v-if="img.uploading">
                                                    Uploading...
                                                  </template>
                                                  <template v-if="img.url"></template>
                                                  <template v-if="!img.uploading && !img.url">
                                                    <span class="badge bg-success">+</span>
                                                  </template>
                                                </span>
                                                
                                                <input accept="image/*" type="file" class="form-control"
                                                  style="position: relative; top: 0; left: 0; width: 100%; height: 100%; opacity: 0; z-index: -1;"
                                                  @change="handleFileUpload($event, idximg)" />
                                              </label>
                                              <div v-if="img.url" @click="removeImage($event, idximg)" class="badge bg-danger" style="position: absolute; top: 5px; right: 5px; z-index: 4; cursor: pointer;">X</div>
                                            </div>


                                          </div>

                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>

                                  
                              </div>

                              <div class="col-md-12 my-2" v-if="products_information.infor.id">
                                  <!-- <label class="block w-24 text-sm font-medium text-gray-700 dark:text-white">Chi tiết</label> -->
                                  <!-- <input type="text" class="form-control ih-medium ip-gray radius-xs b-light px-15"
                                    v-model="products_information.infor.information.details"
                                  > -->
                                  <!-- {{ products_information.infor.information.details }} -->
                                  <tinycme-editor v-model="products_information.infor.information.details"></tinycme-editor>

                                <div class="dm-button-list d-flex flex-wrap my-2">
                                  <button
                                    @click="handleSaveProductInformation()"
                                    :disabled="isButtonDisabled"
                                    type="button"
                                    class="btn btn-success btn-default btn-squared px-3 py-2"
                                    >
                                    Lưu chỉnh sửa
                                  </button>
                                </div>
                                
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="dm-collapse">
                      <div class="dm-collapse-item">
                        <div class="dm-collapse-item__header">
                          <a 
                            class="item-link" 
                            data-bs-toggle="collapse" 
                            data-bs-target="#collapse-body-1" 
                            aria-expanded="false"
                            @click="toggleIcon(3)"
                          >
                            <i :class="{'la la-angle-right': !isOpen[1], 'la la-angle-down': isOpen[3]}"></i>
                            <h6>Xóa sản phẩm</h6>
                          </a>
                        </div>
                        <div v-show="isOpen[3]" class="dm-collapse-item__body">
                          <div class="collapse-body-text">

                            <div class="row">
                              <div class="col-md-12 my-2">
                                
                                <div class="card">
                                  <div class="card-body pt-sm-20 pt-3 d-flex flex-wrap">
                                    <div class="">
                                      <div class="">
                                        <div 
                                          v-for="(item, index) in products_information.products" 
                                          :key="index" 
                                          class="checkbox-theme-default custom-checkbox"
                                        >
                                          <input 
                                            class="checkbox"
                                            type="checkbox" 
                                            :id="'check-grp-' + index"
                                            v-model="item.checked"
                                            @click="checkAllDeleteProductWatch"
                                          >
                                          <label :for="'check-grp-' + index">
                                            <span class="checkbox-text">{{ item.name }}</span>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                              
                                  </div>
                                </div>
      
                                <!-- @click="handleCreateProduct" -->
                                <div class="dm-button-list d-flex flex-wrap my-2">
                                  <button
                                    @click="handleDeleteProducts"
                                    type="button"
                                    class="btn btn-danger btn-default btn-squared px-3 py-2"
                                    >
                                    Xóa sản phẩm
                                  </button>
                                </div>
                                
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                  
                </div>
              </div>
            </div>


            <div class="col-lg-12">
                <div class="row">
                  <div class="col-lg-6 mb-25">

                    <div class="card border-0 card-body pb-30 pt-1">
                      <div class="dm-button-list d-flex flex-wrap">
                        <button 
                          class="btn btn-success btn-default btn-squared btn-transparent-success py-3 px-4"
                          type="button"
                          @click="selectTab(1)"
                          >
                          Thêm Giờ
                        </button>
                      </div>
  
                      <div id="card-type-tab-2" role="tabpanel" aria-labelledby="card-type-tab-item-2">
          
                        <div class="row">
                          <div class="col-md-12 my-2">
                            <label class="block w-24 text-sm font-medium text-gray-700 dark:text-white">Tên</label>

                            <input 
                              type="text"
                              class="form-control ih-medium ip-gray radius-xs b-light px-15"
                              v-model="searchOptions.searchTerm"
                              @input="filterOptions"
                              @blur="validateSelection"
                              placeholder="Nhập tên sản phẩm..."
                            >
                            <ul v-if="searchOptions.filteredOptions.length">
                              <li 
                                v-for="option in searchOptions.filteredOptions" 
                                :key="option.id" 
                                @click="selectOption(option)"
                                class="dropdown-item"
                              >
                                {{ option.name }}
                              </li>
                            </ul>

  
                            <label class="block w-24 text-sm font-medium text-gray-700 dark:text-white">Bắt đầu</label>
                            <div class="ih-medium ip-gray">
                              <input class="form-control ih-medium ip-gray radius-xs b-light px-15"
                                v-model="timeline_generate.time.from"
                                type="time"
                                id="time"
                                min="07:00"
                                max="23:00"
                                @input="handleTimeInputStartEnd"
                                required />
                            </div>
  
                            <label class="block w-24 text-sm font-medium text-gray-700 dark:text-white">Kết thúc</label>
                            <div class="ih-medium ip-gray">
                              <input class="form-control ih-medium ip-gray radius-xs b-light px-15"
                                v-model="timeline_generate.time.to"
                                type="time"
                                id="time"
                                min="07:00"
                                max="23:00"
                                @input="handleTimeInputStartEnd"
                                required />
                            </div>
  
                            <label class="block w-24 text-sm font-medium text-gray-700 dark:text-white">Mỗi khung giờ</label>
                            <div class="ih-medium ip-gray">
                              <input class="form-control ih-medium ip-gray radius-xs b-light px-15"
                                v-model="timeline_generate.time.step"
                                type="time"
                                id="time"
                                min="01:00"
                                max="23:00"
                                @input="handleTimeInput"
                                value="01:00" required />
                            </div>
  
                            <label class="block w-24 text-sm font-medium text-gray-700 dark:text-white">Giá mặc định</label>
                            <input class="form-control ih-medium ip-gray radius-xs b-light px-15"
                              type="text"
                              v-model="timeline_generate.time.price_default"
                              @input="formatThousand"
                              @blur="validateValue"
                              :min="min"
                              :max="max"
                            />
  
                            <div class="dm-button-list d-flex flex-wrap my-2">
                              <button
                                @click="handleGenerate"
                                :disabled="[
                                  searchOptions.searchTerm != null && 
                                  searchOptions.searchTerm != undefined &&
                                  searchOptions.searchTerm.length > 0
                                ].includes(false)"
                                type="button"
                                class="btn btn-primary btn-default btn-squared btn-transparent-primary px-3 py-2"
                                >
                                Thêm khung giờ
                              </button>
  
                              <button
                                @click="handleClearTimeLine"
                                type="button"
                                class="btn btn-danger btn-default btn-squared btn-transparent-danger px-3 py-2"
                                >
                                Xóa hết
                              </button>
  
                              <button
                                @click="handleSaveAll"
                                type="button"
                                class="btn btn-success btn-default btn-squared px-3 py-2"
                                >
                                Lưu tất cả
                              </button>
                            </div>
                            
                          </div>
                        </div>
  
                        <div v-if="timeline_generate.data.length > 0" class="card-body pb-30 pt-1">
                          <div class="team-members">
                            <ul>
                                <li v-for="(item, index) in timeline_generate.data" :key="index">
                                  <div class="team-members__title">
                                    <h6>{{ item.name }} | </h6>
                                  </div>
                                  <div class="team-members__title mx-4">
                                    <h6>{{ formatTime(item.start) }} - {{ formatTime(item.end) }}</h6>
                                  </div>
                                  <div class="team-members__middle text-aligh">
                                    <input
                                      type="text"
                                      class="py-2 px-3 border rounded-lg text-sm border-gray-300 
                                      focus:border-blue-500 focus:ring-blue-500 dark:bg-neutral-900 
                                      dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 
                                      dark:focus:ring-neutral-600"
                                      v-model="item.price"
                                      @input="formatThousandByData(index)"
                                      @blur="validateValueByData(index)"
                                      :min="timeline_generate.time.price_structure.min"
                                      :max="timeline_generate.time.price_structure.max"
                                    />
                                  </div>
                                  <div class="team-member__right">
                                      <div class="team-member__add">
                                        <button type="button" 
                                        class="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-red-100 text-red-800 hover:bg-red-200 focus:outline-none focus:bg-red-200 disabled:opacity-50 disabled:pointer-events-none dark:text-red-500 dark:bg-red-800/30 dark:hover:bg-red-800/20 dark:focus:bg-red-800/20"
                                        @click="deleteItemTimeSlot(index)"
                                        >
                                          X
                                        </button>
                                      </div>
                                  </div>
                                </li>
                            </ul>
                          </div>
                        </div>
                
                      </div>
                    </div>

                    

                  </div>
                  <div class="col-lg-6 mb-25">

                      <div class="card border-0 card-body pb-30 pt-1">
                        <div class="dm-button-list d-flex flex-wrap">
                          <button 
                            class="btn btn-info btn-default btn-squared btn-transparent-info py-3 px-4"
                            type="button"
                            @click="selectTab(2)"
                            >
                            Chỉnh Giờ
                          </button>
                        </div>

                        <!-- ends: .card-header -->
                        <div v-if="temporary.schedules && temporary.schedules.length > 0"  class="pb-30 pt-20">
                          <div class="dm-button-list d-flex flex-wrap">
                            <button v-for="(s, idx) in temporary.schedules" :key="idx"
                              @click="temporary.selectedName = idx"
                              :class="[
                                'btn btn-default btn-squared color-info btn-outline-info py-2',
                                temporary.selectedName === idx
                                  ? 'btn btn-info btn-default btn-squared btn-shadow-info'
                                  : ''
                              ]"
                            >
                              {{ s.name }}
                            </button>
                          </div>

                          <div class="team-members pt-20">
                            <ul>
                                <li v-for="(item, index) in temporary.schedules[temporary.selectedName].times" :key="index">
                                  <div class="team-members__title">
                                    <h6>{{ temporary.schedules[temporary.selectedName].name }} | </h6>
                                  </div>
                                  <div class="team-members__title mx-4">
                                    <h6>{{ formatTime(item.start) }} - {{ formatTime(item.end) }}</h6>
                                  </div>
                                  <div class="team-members__middle text-aligh">
                                    <input
                                      type="text"
                                      class="py-2 px-3 border rounded-lg text-sm border-gray-300 
                                      focus:border-blue-500 focus:ring-blue-500 dark:bg-neutral-900 
                                      dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 
                                      dark:focus:ring-neutral-600"
                                      v-model="item.price"
                                      @input="formatThousandByDataForOld(index)"
                                      @blur="validateValueByDataForOld(index)"
                                      :min="timeline_generate.time.price_structure.min"
                                      :max="timeline_generate.time.price_structure.max"
                                    />
                                  </div>
                                  <div class="team-member__right">
                                      <div class="team-member__add">
                                        <button type="button" 
                                          class="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-red-100 text-red-800 hover:bg-red-200 focus:outline-none focus:bg-red-200 disabled:opacity-50 disabled:pointer-events-none dark:text-red-500 dark:bg-red-800/30 dark:hover:bg-red-800/20 dark:focus:bg-red-800/20"
                                          @click="deleteItemTimeSlotOld(index)"
                                          >
                                            X
                                        </button>
                                      </div>
                                  </div>
                                </li>
                            </ul>
                          </div>

                          <div class="dm-button-list d-flex flex-wrap my-2">
                            <button type="button"
                              class="btn btn-danger btn-default btn-squared btn-transparent-danger px-3 py-2"
                              @click="handleCancelTimeSlotOld()"
                              >
                              Hủy chỉnh sửa
                            </button>
                            <button type="button" 
                              class="btn btn-success btn-default btn-squared px-3 py-2"
                              @click="handleUpdateTimeSlotOld()"
                              >
                              Lưu tất cả
                            </button>
                          </div>
                        
                        </div>
                        <!-- ends: .card-body -->
                      </div>

                  </div>
                </div>
            </div>
          </div>
          <!-- ends: .row -->
      </div>
    </main>

    <!-- Tab Navigation -->
    <div class="border-b border-gray-200 dark:border-neutral-700">
      <nav class="flex gap-x-1" aria-label="Tabs" role="tablist" aria-orientation="horizontal">
        <!-- Thêm Giờ Tab -->
        
      </nav>
    </div>
  </div>
</template>
<script setup>
import AdminHeader from './../../components/AdminHeader.vue';
import apiResource from '../../composables/apiResource.js';
import { checkAdminThemeId } from './../../utils/themeUtils.js';
import { sendMessage } from './../../utils/messageUtils';
import { ref, reactive, onMounted, watch, computed } from 'vue';
import TinycmeEditor from '../../components/tinymce.vue';

const {
  admin_get_schedule,
  admin_new_schedule,
  admin_update_schedule,
  admin_get_product,
  admin_create_product,
  admin_delete_product,
  admin_update_product,
  public_upload_file
} = apiResource();

const loading = ref(false);
const editorData = ref('<p>Nội dung</p>');
watch(editorData, (newValue) => {
  console.log(newValue);
});

const temporary = reactive({
  activeTab: 1,
  selectedName: 0,
  schedules: [],
  updated: [],
  deleted: [],
  product: {}
});

const timeline_generate = reactive({
  time: {
    name: '',
    from: '07:00',
    to: '23:00',
    step: '01:00',
    price_default: '10,000',
    price_structure: {
      min: 2000,
      max: 1000000000
    }
  },
  data: []
})

const selectTab = (tab) => {
  temporary.activeTab = tab;
};

const resetTimeline = () => {
  timeline_generate.time = {
    name: '',
    from: '07:00',
    to: '23:00',
    step: '01:00',
    price_default: '10,000',
    price_structure: {
      min: 10000,
      max: 1000000000
    }
  };
  timeline_generate.data = []; // Reset dữ liệu
};

const resetTemporary = () => {
  temporary.selectedName = 0;
  temporary.schedules = [];
  temporary.updated = [];
  temporary.deleted = [];
}

const formatTime = (timeString) => {
  return new Date(timeString).toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit', hour12: false });
};

const handleTimeInput = (event) => {
  const input = event.target;
  const [hours, minutes] = input.value.split(':');
  
  const roundedMinutes = Math.floor(Number(minutes) / 15) * 15;
  const validMinutes = roundedMinutes < 10 ? '00' : String(roundedMinutes).padStart(2, '0');

  input.value = `${hours}:${validMinutes}`;
}

const handleTimeInputStartEnd = (event) => {
  let [hours, minutes] = event.target.value.split(':');
  minutes = String(Math.floor(minutes / 15) * 15).padStart(2, '0');
  
  // Tạo giá trị thời gian mới sau khi đã làm tròn phút
  let newTime = `${hours}:${minutes}`;
  
  // Giới hạn giá trị theo min và max
  if (newTime < '07:00') newTime = '07:00';
  if (newTime > '23:00') newTime = '23:00';

  event.target.value = newTime;
};

const formatThousand = () => {
  let value = timeline_generate.time.price_default.replace(/\D/g, '');
  if (value) {
    timeline_generate.time.price_default = Number(value).toLocaleString('US');
  }
}

const validateValue = () => {
  let value = Number(timeline_generate.time.price_default.replace(/,/g, '').replace(/\./g, ''));
  if (value < timeline_generate.time.price_structure.min) value = timeline_generate.time.price_structure.min;
  if (value > timeline_generate.time.price_structure.max) value = timeline_generate.time.price_structure.max;
  timeline_generate.time.price_default = value ? value.toLocaleString('US') : '';
}


const formatNumber = (value) => {
  const numberValue = Number(value);
  
  let formattedValue = numberValue.toLocaleString('US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  formattedValue = formattedValue.replace(/,/g, '.').replace(/\./g, ',')
  return formattedValue;
};


const validateAndFormatValue = (value, min, max) => {
  if (value < min) value = min;
  if (value > max) value = max;

  return value ? value.toLocaleString('US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }) : '';
};

/////////////////////
// FOR NEW GENERATE
const formatThousandByData = (index) => {
  let value = timeline_generate.data[index].price.replace(/\D/g, '');
  if (value) {
    timeline_generate.data[index].price = formatNumber(value);
  }
};

const validateValueByData = (index) => {
  let value = Number(timeline_generate.data[index].price.replace(/\./g, '').replace(/,/g, '')); // Replace comma for formatting
  
  // Validate and format the value
  timeline_generate.data[index].price = validateAndFormatValue(value, timeline_generate.time.price_structure.min, timeline_generate.time.price_structure.max);
};
//
/////////////////////


/////////////////////
//  FOR OLD SCHEDULE
const formatThousandByDataForOld = (index) => {
  let value = temporary.schedules[temporary.selectedName].times[index].price.replace(/\D/g, '');
  if (value) {
    temporary.schedules[temporary.selectedName].times[index].price = formatNumber(value);
  }
};


const validateValueByDataForOld = (index) => {
  let value = Number(temporary.schedules[temporary.selectedName].times[index].price.replace(/\./g, '').replace(/,/g, '')); // Replace comma for formatting

  // Validate and format the value
  temporary.schedules[temporary.selectedName].times[index].price = validateAndFormatValue(value, timeline_generate.time.price_structure.min, timeline_generate.time.price_structure.max);
};
//
/////////////////////
const formatMoneyScheduleAll = () => {
  if (temporary.schedules) {
    temporary.schedules.map(item => {
      item.times.map(time => {
        time.price = Number(Number.parseFloat(time.price.toString().replace(/,/g, ''))).toLocaleString()
      })
    })
  } 
}
const handleReloadScheduleAll = async () => {
  temporary.schedules = await admin_get_schedule();
  formatMoneyScheduleAll();
};

onMounted(async() => {
  loading.value = true;
  await handleReloadScheduleAll();
  await checkAdminThemeId();
  await loadProducts();
  loading.value = false;
})

const handleGenerate = () => {
  const name = timeline_generate.time.name;

  // Parse 'from', 'to', and 'step' times as hours and minutes
  const parseTime = (timeString) => {
    const [hours, minutes] = timeString.split(':').map(Number);
    return hours + minutes / 60;
  };

  const from = parseTime(timeline_generate.time.from);
  const to = parseTime(timeline_generate.time.to);
  const step = parseTime(timeline_generate.time.step); // Parse the step as hours and minutes
  const price_default = Number(
    Number.parseFloat(timeline_generate.time.price_default.replace(/,/g, ''))
  ).toLocaleString();

  if (step <= 0) return;

  const currentDate = new Date(new Date().setHours(0, 0, 0, 0)).toISOString();

  if (from < to) {
    let i = from;
    while (i < to) {
      let hour = Math.floor(i); // Get the integer part (e.g., 8 from 8.5)
      let minutes = (i % 1) * 60; // Get the fractional part and convert to minutes (e.g., 0.5 * 60 = 30)

      // Create new Date objects for start and end
      let start = new Date();
      let end = new Date();

      start.setHours(hour, minutes, 0, 0); // Set hour and minute for start
      end.setHours(
        hour + Math.floor(step),
        minutes + (step % 1) * 60,
        0,
        0
      ); // Set hour and minutes for end

      // Ensure that the end time does not exceed the 'to' time
      if (parseTime(`${end.getHours()}:${end.getMinutes()}`) > to) {
        break;
      }

      let modal = {
        start: start.toISOString(),
        end: end.toISOString(),
        name: name,
        start_active: currentDate,
        end_active: new Date(new Date().setHours(24, 0, 0, 0)).toISOString(),
        price: price_default,
        is_active: true,
      };

      timeline_generate.data.push(modal);

      i += step;
    }
  } else if (to < from) {
    let hour_from = Math.floor(from);
    let minutes_from = (from % 1) * 60;

    let hour_to = Math.floor(to);
    let minutes_to = (to % 1) * 60;

    let start = new Date();
    let end = new Date();

    start.setHours(hour_from, minutes_from, 0, 0);
    end.setHours(hour_to, minutes_to, 0, 0);
    
    let modal = {
      start: start.toISOString(),
      end: end.toISOString(),
      name: name,
      start_active: currentDate,
      end_active: new Date(new Date().setHours(24, 0, 0, 0)).toISOString(),
      price: price_default,
      is_active: true,
    };
    timeline_generate.data.push(modal);
  }

  timeline_generate.data = timeline_generate.data.sort(
    (a, b) => new Date(a.start) - new Date(b.start)
  );
}

const handleClearTimeLine = () => {
  timeline_generate.data = [];
}

const handleSaveAll = async () => {
  try {
    timeline_generate.data.forEach(i => {
      i.price = Number(i.price.replace(/,/g, ''));
    })

    await admin_new_schedule(timeline_generate.data);

    sendMessage('Thêm khung giờ hoàn tất', 'success', 'check-circle')
    resetTimeline();
    await handleCancelTimeSlotOld();
  } catch (error) { }
};

const deleteItemTimeSlot = (index) => {
  timeline_generate.data.splice(index, 1);
}

const handleCancelTimeSlotOld = async () => {
  resetTemporary();
  await handleReloadScheduleAll();
}

const handleUpdateTimeSlotOld = async () => {
  temporary.updated = [];

  temporary.schedules.map(item => {
    item.times.map(t => {
      temporary.updated.push({
        ...t,
        price: Number.parseFloat(t.price.replace(/,/g, '').replace(/\./g, '')),
        name: item.name
      })
    })
  });

  const response = await admin_update_schedule({
    update: temporary.updated,
    delete: temporary.deleted
  });

  if (response) {
    sendMessage('Chỉnh sửa khung giờ hoàn tất', 'success', 'check-circle')
    await handleCancelTimeSlotOld()
  }
}

const deleteItemTimeSlotOld = (index) => {
  temporary.deleted.push({
    ...temporary.schedules[temporary.selectedName].times[index],
    price: Number.parseFloat(temporary.schedules[temporary.selectedName].times[index].price.replace(/,/g, '').replace(/\./g, '')),
    name: temporary.schedules[temporary.selectedName].name
  });
  temporary.schedules[temporary.selectedName].times.splice(index, 1);
}


///////////////////////////
// PRODUCT INFORMATION
//
const searchOptions = reactive({
  searchTerm: '',
  filteredOptions: []
})
const products_information = reactive({
  checkedAll: false,
  products: [],
  infor: {
    id: '',
    information: {}
  }
})
// let products = reactive();

const isOpen = ref({
  1: false,
});

const toggleIcon = (index) => {
  isOpen.value[index] = !isOpen.value[index];
};

const loadProducts = async () => {
  const products_loaded = await admin_get_product();

  if (products_loaded) {
    if (products_loaded.status == 400) {
      sendMessage(products_loaded.message, 'danger', 'sun-o')
    } else if (products_loaded.length > 0) {
      products_information.products = products_loaded

      products_information.products.map(p => {
        p.checked = false;
      })
    }
  }
}

const validateSelection = () => {
  const matchedOption = products_information.products.find(o => o.name_tag === searchOptions.searchTerm.toLowerCase());
  
  if (!matchedOption) {
    searchOptions.searchTerm = '';
  } else {
    timeline_generate.time.name = matchedOption.name;
  }
}

const filterOptions = () => {
  searchOptions.filteredOptions = products_information.products.filter(o =>
    o.name_tag.includes(searchOptions.searchTerm.toLowerCase())
  )
}

const selectOption = (o) => {
  timeline_generate.time.name = o.name;
  searchOptions.searchTerm = o.name;
  searchOptions.filteredOptions = []; // Close the dropdown after selection
};

const handleCreateProduct = async () => {
  const productSaved = await admin_create_product(temporary);

  if (productSaved.status == 400) {
    sendMessage(productSaved.statusText, 'danger', 'sun-o')
  } else {
    sendMessage('Lưu thành công', 'success', 'check-circle')
    await loadProducts()
  }
};


///Delete products
const handleDeleteProducts = async () => {
  const ids = [] // ids to delete
  products_information.products.map(p => {
    if (p.checked) ids.push(p.id)
  })

  const productDeleted = await admin_delete_product({
    ids: ids
  });

  if (productDeleted.status == 400) {
    sendMessage(productDeleted.statusText, 'danger', 'sun-o')
  } else {
    sendMessage('Xóa thành công', 'success', 'check-circle')
    await loadProducts()
  }
}

const loadProductInformation = async (p) => {
  products_information.infor.id = p.id;
  products_information.infor.information = p.information;
  // editorData.value = products_information.infor.information.details;
}

const removeImage = (index) => {
  products_information.infor.information.imgs.splice(index, 1);
}

const handleAddImageInformation = () => {
  if (products_information.infor?.information?.imgs) {
    products_information.infor.information?.imgs.push({
      url: ''
    })
  } else {
    products_information.infor.information.imgs = [{
      url: ''
    }]
  }

  if (products_information.infor?.information?.imgs.length > 3) {
    products_information.infor.information.imgs.pop();
    sendMessage('Quá số lượng hình cho phép', 'danger', 'sun-o')
  }
}

const isButtonDisabled = computed(() => {
  if (products_information.infor?.id) {
    return false;
  }

  return true;
});

const handleSaveProductInformation = async () => {
  const productUpdated = await admin_update_product(products_information.infor);

  if (productUpdated.status == 400) {
    sendMessage(productUpdated.statusText, 'danger', 'sun-o')
  } else {
    sendMessage('Cập nhật thành công', 'success', 'check-circle')
    await loadProducts();
  }
}

const triggerFileInput = (idximg) => {
  document.querySelectorAll('.file-input-list')[idximg].click();
};

const sanitizeFileName = (fileName) => {
  return `${(new Date()).toISOString().replace(/[^a-zA-Z0-9._-]/g, '_')}_${fileName.replace(/[^a-zA-Z0-9._-]/g, '_')}`;
};

import imageCompression from 'browser-image-compression';


const handleFileUpload = async (event, idximg) => {
  const file = event.target.files[0];
  if (!file) return;

  try {
    // Compress the image
    const compressedFile = await imageCompression(file, {
      maxSizeMB: 0.08,
      maxWidthOrHeight: 1000,
      useWebWorker: true,
    });

    const sanitizedFileName = sanitizeFileName(compressedFile.name);
    const newFile = new File([compressedFile], sanitizedFileName, { type: compressedFile.type });

    products_information.infor.information.imgs[idximg].uploading = true;
    
    const uploaded = await public_upload_file(newFile);

    if (uploaded.status === 400) {
      sendMessage(uploaded.statusText, 'danger', 'sun-o');
    } else {
      products_information.infor.information.imgs[idximg].url = uploaded.fileName;
    }
  } catch (error) {
    console.error('Upload error:', error);
    sendMessage('Error uploading image', 'danger', 'sun-o');
  } finally {
    products_information.infor.information.imgs[idximg].uploading = false;
  }
};

const handleFileUploadMain = async (event) => {
  const file = event.target.files[0];
  if (!file) return;

  console.log('handleFileUploadMain')

  try {
    // Compress the image
    const compressedFile = await imageCompression(file, {
      maxSizeMB: 0.08,
      maxWidthOrHeight: 1000,
      useWebWorker: true,
    });

    const sanitizedFileName = sanitizeFileName(compressedFile.name);
    const newFile = new File([compressedFile], sanitizedFileName, { type: compressedFile.type });

    temporary.avatar_uploading = true;
    
    const uploaded = await public_upload_file(newFile);

    if (uploaded.status === 400) {
      sendMessage(uploaded.statusText, 'danger', 'sun-o');
    } else {
      temporary.avatar_uploading = false;
      products_information.infor.information.avatar = uploaded.fileName;
    }
  } catch (error) {
    console.error('Upload error:', error);
    sendMessage('Error uploading image', 'danger', 'sun-o');
  } finally {
    temporary.avatar_uploading = false;
  }
};

//
// PRODUCT INFORMATION
///////////////////////////


</script>