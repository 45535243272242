import { useRouter } from 'vue-router';

export const useLogout = () => {
  const router = useRouter();

  const kickLogout = () => {
    localStorage.removeItem('access_token'); // Remove token from localStorage
    localStorage.removeItem('userRole'); // Remove user role from localStorage
    localStorage.removeItem('theme_admin_id');
    // alert('Session expired. Please log in again.'); // Alert the user
    router.push({ path: '/' }); // Redirect to login
  };

  return { kickLogout };
};
