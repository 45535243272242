<template>
  <div v-if="loading" class="loading-overlay">
    <div class="dot"></div>
    <span class="text">
      Loading
    </span>
  </div>

  <div v-else>
    <div class="login-page">
      <div class="form">
        <form class="login-form" @submit.prevent="handleLogin">
          <input
            type="email"
            id="email"
            v-model="temporary.login.username"
            required
            placeholder="username"
          />
          <input
            type="password"
            id="password"
            v-model="temporary.login.password"
            required
            placeholder="password"
          />
          <button>login</button>
          <p v-if="temporary.error" class="message"> {{ temporary.error }} </p>
        </form>
      </div>
    </div>
  </div>

</template>
<script setup>
import VueJwtDecode from 'vue-jwt-decode';
import { useRouter } from 'vue-router';
import { ref, reactive, onMounted } from 'vue';

const router = useRouter();

import apiResource from '../../composables/apiResource.js';
const {
  auth_login,
} = apiResource();

const loading = ref(false);

const temporary = reactive({
  login: {
    username: '',
    password: ''
  },
  error: ''
});

const handleLogin = async () => {
  try {
    const response = await auth_login(temporary.login);

    if (response.access_token) {
      localStorage.setItem('access_token', response.access_token);
      const token = localStorage.getItem('access_token');
      if (token) {
        const decodedToken = VueJwtDecode.decode(token);
        if (decodedToken) {

          if (decodedToken.organization) {
            localStorage.setItem('organization', decodedToken.organization);
          }

          if (decodedToken.theme_admin_id) {
            localStorage.setItem('theme_admin_id', decodedToken.theme_admin_id);
          }

          if (decodedToken.role) {
            localStorage.setItem('userRole', decodedToken.role);

            // Check if the user role is 'admin', then redirect to the dashboard
            if (decodedToken.role === 'admin') {
              router.push({ path: '/dashboard' });
            } else {
              // Handle non-admin user redirection if needed
              router.push({ path: '/' });
            }
          }
        }
      }
    } else {
      temporary.error = 'Thông tin đăng nhập không đúng'
    }
  } catch (error) { 
    console.error('Login failed', error);
  }
};

const checkThemeId = async () => {
  await import('./../../assets/login.css');
  loading.value = false;
}

onMounted(async() => {
  loading.value = true;
  await checkThemeId();
})
</script>
