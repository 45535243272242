<template>
  <div v-if="loading" class="loading-overlay">
    <div class="dot"></div>
    <span class="text">
      Loading
    </span>
  </div>

  <div v-else>
    <AdminHeader />

    <div class="breadcrumb-main px-4">
      <h4 class="text-capitalize breadcrumb-title">Giới thiệu tài khoản</h4>
      <div class="breadcrumb-action justify-content-center flex-wrap">
         <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
               <li class="breadcrumb-item"><a href="/dashboard"><i class="uil uil-estate"></i>Trang chủ</a></li>
               <li class="breadcrumb-item active" aria-current="page">Giới thiệu tài khoản</li>
            </ol>
         </nav>
      </div>
    </div>

    <main class="main-content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="card">

              <div class="dm-button-list d-flex flex-wrap">
                <button 
                  class="btn btn-primary btn-default btn-squared btn-shadow-primary py-3 px-4"
                  type="button"
                  disabled: disabled
                  >
                  Giới thiệu thành viên
                </button>
                <div class="position-absolute" style="top: 0; right: 0;">
                  <button
                    type="submit"
                    @click="handleCreate"
                    class="btn btn-success btn-default btn-squared px-3 py-2">
                    Tạo mới
                  </button>
                </div>
              </div>

              <div class="py-3 px-3">
                <form>
                  <div class="form-group row">
                     <div class="col-sm-2 d-flex aling-items-center">
                        <label class=" col-form-label color-dark fs-14 fw-500 align-center">Email</label>
                     </div>
                     <div class="col-sm-6">
                       <input
                          v-model="temporary.user.username"
                          type="email"
                          class="form-control ih-medium ip-light radius-xs b-light py-1" 
                         >
                     </div>
                  </div>

                  <div class="form-group row">
                    <div class="col-sm-2 d-flex aling-items-center">
                       <label class=" col-form-label color-dark fs-14 fw-500 align-center">Mật khẩu</label>
                    </div>
                    <div class="col-sm-6">
                      <input
                          v-model="temporary.user.password"
                          type="password"
                          class="form-control ih-medium ip-light radius-xs b-light py-1" 
                        >
                      </div>
                  </div>

                  <div class="form-group row">
                      <div class="col-sm-2 d-flex aling-items-center">
                        <label class=" col-form-label color-dark fs-14 fw-500 align-center">Tên tổ chức</label>
                      </div>
                      <div class="col-sm-6">
                        <input
                            v-model="temporary.user.organization_name"
                            type="text"
                            class="form-control ih-medium ip-light radius-xs b-light py-1" 
                          >
                      </div>
                  </div>
                </form>

              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted } from 'vue';
import AdminHeader from './../../components/AdminHeader.vue';
import { checkAdminThemeId } from './../../utils/themeUtils.js';
import apiResource from '../../composables/apiResource.js';
import { sendMessage } from './../../utils/messageUtils';

const {
  admin_create_account
} = apiResource();

const loading = ref(false);

let temporary = reactive({
  user: {
    username: '',
    password: '',
    organization_name: '',
  }
});

const handleCreate = async () => {
  const account_created = await admin_create_account(temporary.user);
  
  if (account_created && account_created.error) {
    sendMessage(account_created.error, 'danger', 'sun-o')
  } else {
    sendMessage('Tạo mới thành công', 'success', 'check-circle')
  }
}

onMounted(async() => {
    loading.value = true;
    await checkAdminThemeId();
    loading.value = false;
})

</script>
