<template>
  <div v-if="loading" class="loading-overlay">
    <div class="dot"></div>
    <span class="text">
      Loading
    </span>
  </div>

  <div v-else>
    <AdminHeader />

    <div class="breadcrumb-main px-4">
      <h4 class="text-capitalize breadcrumb-title">Bảo mật</h4>
      <div class="breadcrumb-action justify-content-center flex-wrap">
         <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
               <li class="breadcrumb-item"><a href="/dashboard"><i class="uil uil-estate"></i>Trang chủ</a></li>
               <li class="breadcrumb-item active" aria-current="page">Bảo mật</li>
            </ol>
         </nav>
      </div>
    </div>

    <main class="main-content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">

            <div class="row">
              <div class="col-lg-6 card">
                <div class="dm-button-list d-flex flex-wrap">
                  <button 
                    class="btn btn-primary btn-default btn-squared btn-shadow-primary py-3 px-4"
                    type="button"
                    disabled: disabled
                    >
                    Đổi mật khẩu
                  </button>
                  <div class="position-absolute" style="top: 0; right: 0;">
                    <button
                      type="submit"
                      @click="handleSave"
                      class="btn btn-success btn-default btn-squared px-3 py-2">
                      Lưu
                    </button>
                  </div>
                </div>

                <div class="py-3 px-3">
                  <form>
                    <div class="form-group row">
                      <div class="col-sm-2 d-flex aling-items-center">
                          <label class=" col-form-label color-dark fs-14 fw-500 align-center">Mật khẩu</label>
                      </div>
                      <div class="col-sm-10">
                        <input
                            v-model="temporary.privacy.password.password"
                            type="password"
                            class="form-control ih-medium ip-light radius-xs b-light py-1" 
                          >
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="col-sm-2 d-flex aling-items-center">
                        <label class=" col-form-label color-dark fs-14 fw-500 align-center">Lặp lại</label>
                      </div>
                      <div class="col-sm-10">
                        <input
                            v-model="temporary.privacy.password.passwordrepeat"
                            type="password"
                            class="form-control ih-medium ip-light radius-xs b-light py-1" 
                          >
                        </div>
                    </div>

                </form>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </main>  

  </div>
</template>

<script setup>
import { ref, reactive, onMounted } from 'vue';
import AdminHeader from '../../components/AdminHeader.vue';
import { checkAdminThemeId } from '../../utils/themeUtils.js';
import apiResource from '../../composables/apiResource.js';
import { sendMessage } from '../../utils/messageUtils.js';
const {
  admin_change_password
} = apiResource();

const loading = ref(false);

const temporary = reactive({
  privacy: {
    password: {
      password: '',
      passwordrepeat: ''
    }
  }
});

const resetTemporary = () => {
  temporary.privacy ={
    password: {
      password: '',
      passwordrepeat: ''
    }
  }
}

const handleSave = async () => {
  const privacySaved = await admin_change_password({
    password: temporary.privacy.password
  });

  if (privacySaved.status >= 400) {
    let text = 'Đổi mật khẩu thất bại, vui lòng liên hệ quản trị viên';
    if (privacySaved.statusText) {
      text = privacySaved.statusText
    }
    sendMessage(text, 'danger', 'sun-o')
  } else {
    resetTemporary()
    sendMessage('Đổi mật khẩu thành công', 'success', 'check-circle')
  }
}

onMounted(async() => {
  loading.value = true;
  await checkAdminThemeId();
  loading.value = false;
})

</script>
