<template>
  <header class="header-top">
    <nav class="navbar navbar-light">
        <div class="navbar-left">
          <div class="logo-area">
            <div class="hexadash-top-menu position-relative">
              <ul class="d-flex align-items-center flex-wrap">
                  <li class="has-subMenu">
                    <a class="active">Quản lý</a>
                    <ul class="subMenu">
                        <li class="">
                          <a @click.prevent="$router.push({ name: 'Scheduler' })">
                            <i class="la la-calendar"></i>Lịch
                          </a>
                        </li>
                        <li class="">
                          <a @click.prevent="$router.push({ name: 'Order' })">
                            <i class="la la-cart-arrow-down"></i>Đơn hàng
                          </a>
                        </li>
                        <li class="">
                          <a @click.prevent="$router.push({ name: 'TimeGenerate' })">
                            <i class="la la-clock-o"></i>Chỉnh giờ
                          </a>
                        </li>
                    </ul>
                  </li>

                  <li class="">
                    <a class="author-img">
                      <img src="/src/assets/theme_admin_id_2/img/author-nav.jpg" alt="" class="rounded-circle">
                      {{ organization }}
                    </a>
                    
                    <ul class="subMenu">
                      <li>
                        <a @click.prevent="$router.push({ name: 'Privacy' })">
                          <i class="la la-cubes"></i>Bảo mật
                        </a>
                      </li>
                      <li>
                        <a @click.prevent="$router.push({ name: 'Information' })">
                          <i class="la la-phone"></i>Thông tin
                        </a>
                      </li>
                      <li>
                        <a @click.prevent="$router.push({ name: 'Config' })">
                          <i class="la la-gear"></i>Cấu hình
                        </a>
                      </li>
                      <li class="">
                        <a @click.prevent="$router.push({ name: 'CreateUser' })">
                          <i class="la la-github-alt"></i>Người dùng
                        </a>
                      </li>
                      <li>
                        <a @click="handleLogout">
                          <i class="la la-sign-out-alt"></i>Thoát
                        </a>
                      </li>
                    </ul>
                  </li>
              </ul>
            </div>
              
          </div>
          
        </div>
        <!-- ends: navbar-left -->
        <div class="navbar-right">
          <ul class="navbar-right__menu">
              <li class="nav-flag-select">
                <div class="dropdown-custom">
                    <a class="nav-item-toggle"><img src="./../assets/theme_admin_id_2/img/flag.png" alt="" class="rounded-circle"></a>
                </div>
              </li>
          </ul>
          <!-- ends: .navbar-right__menu -->
        </div>
        <!-- ends: .navbar-right -->
    </nav>
  </header>
</template>
  
<script setup>
import { watch, ref, reactive } from 'vue';
import { useRouter, useRoute } from 'vue-router';

const organization = reactive(localStorage.getItem('organization'));
const theme_id = ref(localStorage.getItem('theme_admin_id'));
const router = useRouter();
const route = useRoute();

watch(route, (newRoute) => {
  // If the route changes, update the userRole accordingly
  userRole.name = localStorage.getItem('userRole') || 'user'; // Refresh user role
});

// Call logout when needed
const handleLogout = () => {
  localStorage.removeItem('userRole');
  localStorage.removeItem('access_token');
  localStorage.removeItem('organization');
  localStorage.removeItem('theme_admin_id');
  router.push({ name: 'Login' }); // Redirect to login
};
</script>

<style scoped>
/* Add any necessary styles for the header */
</style>
  