<template>
  <div v-if="loading" class="loading-overlay">
    <div class="dot"></div>
    <span class="text">
      Loading
    </span>
  </div>

  <div v-else>
    <AdminHeader />

    <div class="breadcrumb-main px-4">
      <h4 class="text-capitalize breadcrumb-title">Cấu hình</h4>
      <div class="breadcrumb-action justify-content-center flex-wrap">
         <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
               <li class="breadcrumb-item"><a href="/dashboard"><i class="uil uil-estate"></i>Trang chủ</a></li>
               <li class="breadcrumb-item active" aria-current="page">Cấu hình</li>
            </ol>
         </nav>
      </div>
    </div>

    <main class="main-content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">

            <div class="row">
              <div class="col-lg-6">

                <div class="row">
                  <div class="col-12 card">
                    <div class="dm-button-list d-flex flex-wrap">
                      <button 
                        class="btn btn-primary btn-default btn-squared btn-shadow-primary py-3 px-4"
                        type="button"
                        disabled: disabled
                        >
                        Cổng thanh toán PAY OS
                      </button>
                      <div class="position-absolute" style="top: 0; right: 0;">
                        <button
                          type="submit"
                          @click="handleSave"
                          class="btn btn-success btn-default btn-squared px-3 py-2">
                          Lưu
                        </button>
                      </div>
                    </div>
    
                    <div class="py-3 px-3">
                      <form>
                        <div class="form-group row">
                          <div class="col-sm-2 d-flex aling-items-center">
                              <label class=" col-form-label color-dark fs-14 fw-500 align-center">CLIENT ID</label>
                          </div>
                          <div class="col-sm-10">
                            <input
                                v-model="temporary.config.payos_info.client_id"
                                type="text"
                                class="form-control ih-medium ip-light radius-xs b-light py-1" 
                              >
                          </div>
                        </div>
    
                        <div class="form-group row">
                          <div class="col-sm-2 d-flex aling-items-center">
                            <label class=" col-form-label color-dark fs-14 fw-500 align-center">API KEY</label>
                          </div>
                          <div class="col-sm-10">
                            <input
                                v-model="temporary.config.payos_info.api_key"
                                type="text"
                                class="form-control ih-medium ip-light radius-xs b-light py-1" 
                              >
                            </div>
                        </div>
    
                        <div class="form-group row">
                            <div class="col-sm-2 d-flex aling-items-center">
                              <label class="col-form-label color-dark fs-14 fw-500 align-center">CHECKSUM KEY</label>
                            </div>
                            <div class="col-sm-10">
                              <input
                                  v-model="temporary.config.payos_info.checksum_key"
                                  type="text"
                                  class="form-control ih-medium ip-light radius-xs b-light py-1" 
                                >
                            </div>
                        </div>
                      
                      </form>
                    </div>
                  </div>

                  <div class="col-12 card">
                    <div class="dm-button-list d-flex flex-wrap">
                      <button 
                        class="btn btn-primary btn-default btn-squared btn-shadow-primary py-3 px-4"
                        type="button"
                        disabled: disabled
                        >
                        Loại hình kinh doanh
                      </button>
                      <div class="position-absolute" style="top: 0; right: 0;">
                        <button
                          type="submit"
                          @click="handleSave"
                          class="btn btn-success btn-default btn-squared px-3 py-2">
                          Lưu
                        </button>
                      </div>
                    </div>
    
                    <div class="py-3 px-3">
                      <form>
                        <div class="form-group row">
                          <div class="col-sm-2 d-flex aling-items-center">
                              <label class=" col-form-label color-dark fs-14 fw-500 align-center">Loại hình</label>
                          </div>
                          <div class="col-sm-10 dm-select">
                            <select v-model="temporary.config.business_info.type"
                              class="form-control ih-medium ip-light radius-xs b-light py-1
                              select2 select2-container select2-container--default">
                              <option v-for="option in typeOptions" :key="option.value" :value="option.value">
                                {{ option.name }}
                              </option>
                            </select>
                          </div>
                        </div>
    
                        <div class="form-group row">
                          <div class="col-sm-2 d-flex aling-items-center">
                            <label class=" col-form-label color-dark fs-14 fw-500 align-center">Qua đêm</label>
                          </div>
                          <div class="col-sm-10">
                            <div class="checkbox-theme-default custom-checkbox ">
                              <input class="checkbox" type="checkbox" id="check-un3" 
                                v-model="temporary.config.business_info.last_time_over_night"
                              >
                              <label for="check-un3">
                                 <span class="checkbox-text">
                                    Khung giờ cuối ngày được đánh dấu "Qua Đêm"
                                 </span>
                              </label>
                           </div>
                          </div>
                        </div>

                        <div class="form-group row">
                          <div class="col-sm-2 d-flex aling-items-center">
                            <label class=" col-form-label color-dark fs-14 fw-500 align-center">Ảnh đại diện</label>
                          </div>
                          <div class="col-sm-10">


                            <div class="" style="position: relative; width: 100px; height: 125px;">
                              <label style="cursor: pointer; width: 100px; height: 125px; border: 1px solid rgb(206, 212, 218); align-items: center; justify-content: center;">
                                <img v-if="temporary.config.business_info.avatar" :src="temporary.config.business_info.avatar" class="img-fluid" style="position: absolute; cursor: pointer; width: 100%; height: 100%; object-fit: cover; z-index: 1;">
                                
                                <span 
                                  style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); cursor: pointer; z-index: 2;">
                                  <template v-if="temporary.config.business_info.avatar_uploading">
                                    Uploading...
                                  </template>
                                  <template v-if="temporary.config.business_info.avatar"></template>
                                  <template v-if="!temporary.config.business_info.avatar_uploading && !temporary.config.business_info.avatar">
                                    <span class="badge bg-success">+</span>
                                  </template>
                                </span>
                                
                                <input accept="image/*" type="file" class="form-control"
                                  style="position: relative; top: 0; left: 0; width: 100%; height: 100%; opacity: 0; z-index: -1;"
                                  @change="handleFileBusinessAvatar($event)" />
                              </label>
                              <div v-if="temporary.config.business_info.avatar" @click="removeBusinessAvatar($event)" class="badge bg-danger" style="position: absolute; top: 5px; right: 5px; z-index: 4; cursor: pointer;">X</div>
                            </div>


                          </div>

                        </div>
                      
                      </form>
                    </div>
                  </div>
                </div>

                
              </div>

              <div class="col-lg-6 card">
                <div class="dm-button-list d-flex flex-wrap">
                  <button 
                    class="btn btn-primary btn-default btn-squared btn-shadow-primary py-3 px-4"
                    type="button"
                    disabled: disabled
                    >
                    Chọn giao diện
                  </button>
                  <div class="position-absolute" style="top: 0; right: 0;">
                    <button
                      type="submit"
                      @click="handleSave"
                      class="btn btn-success btn-default btn-squared px-3 py-2">
                      Lưu
                    </button>
                  </div>
                </div>

                <div class="row">


                  <div class="col-6 mb-30">
                     <div class="card h-100">
                        <div class="card-body p-30">
                           <div class="pricing d-flex align-items-center">
                              <span class="pricing__tag color-secondary order-bg-opacity-secondary rounded-pill">
                                Giao diện mặc định
                              </span>
                           </div>
                           <div class="pricing__price rounded">
                              <p class="pricing_value display-3 color-dark d-flex align-items-center text-capitalize fw-600 mb-1">
                                 <sup>$</sup>39
                                 <small class="pricing_user">Per month</small>
                              </p>
                              <p class="pricing_subtitle mb-0">For 10 Users</p>
                           </div>
                           <div class="pricing__features">
                              <ul>
                                 <li><span class="fa fa-check"></span>500GB+ file space</li>
                                 <li><span class="fa fa-check"></span>Unlimited projects</li>
                                 <li><span class="fa fa-check"></span>Unlimited boards</li>
                                 <li><span class="fa fa-check"></span>Basic project management</li>
                                 <li><span class="fa fa-check"></span>Custom Post Types</li>
                                 <li><span class="fa fa-check"></span>Subtasks</li>
                              </ul>
                           </div>
                        </div>
                        <div class="price_action d-flex pb-30 ps-30">
                           <label class="d-flex align-items-center">
                              <input type="radio" name="pricing_plan" :value="1" v-model="temporary.config.theme_id" class="me-2">
                              <span class="text-capitalize">Chọn mặc định</span>
                           </label>
                        </div>
                     </div><!-- End: .card -->
                  </div>


                  <div class="col-6 mb-30">
                     <div class="card h-100">
                        <div class="card-body p-30">
                           <div class="pricing d-flex align-items-center">
                              <span class="pricing__tag color-success order-bg-opacity-success rounded-pill">
                                Giao diện Khách Sạn
                              </span>
                           </div>
                           <div class="pricing__price rounded">
                              <p class="pricing_value display-3 color-dark d-flex align-items-center text-capitalize fw-600 mb-1">
                                 <sup>$</sup>79
                                 <small class="pricing_user">Per month</small>
                              </p>
                              <p class="pricing_subtitle mb-0">For 50 Users</p>
                           </div>
                           <div class="pricing__features">
                              <ul>
                                 <li><span class="fa fa-check"></span>500GB+ file space</li>
                                 <li><span class="fa fa-check"></span>Unlimited projects</li>
                                 <li><span class="fa fa-check"></span>Unlimited boards</li>
                                 <li><span class="fa fa-check"></span>Basic project management</li>
                                 <li><span class="fa fa-check"></span>Custom Post Types</li>
                                 <li><span class="fa fa-check"></span>Subtasks</li>
                              </ul>
                           </div>
                        </div>
                        <div class="price_action d-flex pb-30 ps-30">
                           <label class="d-flex align-items-center">
                              <input type="radio" name="pricing_plan" :value="2" v-model="temporary.config.theme_id" class="me-2">
                              <span class="text-capitalize">Chọn Hotels</span>
                           </label>
                        </div>
                     </div><!-- End: .card -->
                  </div>


                  <div class="col-6 mb-30">
                    <div class="card h-100">
                       <div class="card-body p-30">
                          <div class="pricing d-flex align-items-center">
                             <span class="pricing__tag color-success order-bg-opacity-success rounded-pill">
                               Giao diện Building
                             </span>
                          </div>
                          <div class="pricing__price rounded">
                             <p class="pricing_value display-3 color-dark d-flex align-items-center text-capitalize fw-600 mb-1">
                                <sup>$</sup>40
                                <small class="pricing_user">Per month</small>
                             </p>
                             <p class="pricing_subtitle mb-0">For 50 Users</p>
                          </div>
                          <div class="pricing__features">
                             <ul>
                                <li><span class="fa fa-check"></span>500GB+ file space</li>
                                <li><span class="fa fa-check"></span>Unlimited projects</li>
                                <li><span class="fa fa-check"></span>Unlimited boards</li>
                                <li><span class="fa fa-check"></span>Basic project management</li>
                                <li><span class="fa fa-check"></span>Custom Post Types</li>
                                <li><span class="fa fa-check"></span>Subtasks</li>
                             </ul>
                          </div>
                       </div>
                       <div class="price_action d-flex pb-30 ps-30">
                          <label class="d-flex align-items-center">
                             <input type="radio" name="pricing_plan" :value="3" v-model="temporary.config.theme_id" class="me-2">
                             <span class="text-capitalize">Chọn Building</span>
                          </label>
                       </div>
                    </div><!-- End: .card -->
                 </div>

                </div>
                
              </div>
            </div>

          </div>
        </div>
      </div>
    </main>  

  </div>
</template>

<script setup>
import { ref, reactive, onMounted } from 'vue';
import imageCompression from 'browser-image-compression';
import AdminHeader from './../../components/AdminHeader.vue';
import { checkAdminThemeId } from './../../utils/themeUtils.js';
import apiResource from '../../composables/apiResource.js';
import { sendMessage } from './../../utils/messageUtils';

const {
  admin_get_config,
  admin_update_config,
  public_upload_file,
} = apiResource();

const loading = ref(false);

const typeOptions = [
  { name: 'Homestay', value: 'homestay' },
  { name: 'Badminton', value: 'badminton' },
  { name: 'Football', value: 'football' },
  { name: 'Personal Trainer', value: 'pt' },
  { name: 'Cafe', value: 'cafe' },
  { name: 'Car Detailing', value: 'car_detailing' },
  { name: 'Barbershop', value: 'barbershop' },
  { name: 'Restaurant', value: 'restaurant' },
  { name: 'Hotel', value: 'hotel' },
  { name: 'Spa', value: 'spa' },
  { name: 'Gym', value: 'gym' },
  { name: 'Salon', value: 'salon' },
  { name: 'Yoga Studio', value: 'yoga' },
  { name: 'Dance Studio', value: 'dance' },
  { name: 'Coworking Space', value: 'coworking' },
  { name: 'Event Venue', value: 'event_venue' },
  { name: 'Photography Studio', value: 'photography_studio' },
  { name: 'Massage Therapy', value: 'massage_therapy' },
  { name: 'Pet Care', value: 'pet_care' },
  { name: 'Fitness Center', value: 'fitness_center' },
  { name: 'Beauty Clinic', value: 'beauty_clinic' },
  { name: 'Tattoo Studio', value: 'tattoo_studio' }
];

const temporary = reactive({
  config: {
    payos_info: {
      client_id: '',
      api_key: '',
      checksum_key: '',
    },
    theme_id: 1,
    business_info: {
      type: '',
      last_time_over_night: false,
      avatar: '',
      avatar_uploading: false,
    }
  },
  search: {
    code_label: ''
  },
  order: null,
  customer: {
    is_show: false,
    name: '',
    email: '',
    phone: ''
  }
});

const handleSave = async () => {
  const configSaved = await admin_update_config(temporary.config);

  if (configSaved.status == 400) {
    sendMessage('Lưu không thành công', 'danger', 'sun-o')
  } else {
    temporary.config.payos_info = configSaved.data.payos_info;
    sendMessage('Lưu thành công', 'success', 'check-circle')
  }
}

onMounted(async() => {
  loading.value = true;
  const config = await admin_get_config();

  if (config) {
    if (config.status == 400) {
      sendMessage('Tải dữ liệu config lỗi, vui lòng liên hệ admin', 'danger', 'sun-o')
    } else {
      temporary.config.payos_info = config.payos_info;
      temporary.config.theme_id = config.theme_id;
      temporary.config.business_info = config.business_info;

      await checkAdminThemeId();
      loading.value = false;
    }
  } 
})


///////////////////////////
/// Business Info - Start
const sanitizeFileName = (fileName) => {
  return `${(new Date()).toISOString().replace(/[^a-zA-Z0-9._-]/g, '_')}_${fileName.replace(/[^a-zA-Z0-9._-]/g, '_')}`;
};

const handleFileBusinessAvatar = async (event) => {
  const file = event.target.files[0];
  if (!file) return;

  try {
    // Compress the image
    const compressedFile = await imageCompression(file, {
      maxSizeMB: 0.08,
      maxWidthOrHeight: 1000,
      useWebWorker: true,
    });

    const sanitizedFileName = sanitizeFileName(compressedFile.name);
    const newFile = new File([compressedFile], sanitizedFileName, { type: compressedFile.type });

    temporary.config.business_info.avatar_uploading = true;
    
    const uploaded = await public_upload_file(newFile);

    if (uploaded.status === 400) {
      sendMessage(uploaded.statusText, 'danger', 'sun-o');
    } else {
      temporary.config.business_info.avatar = uploaded.fileName;
    }
  } catch (error) {
    console.error('Upload error:', error);
    sendMessage('Error uploading image', 'danger', 'sun-o');
  } finally {
    temporary.config.business_info.avatar_uploading = false;
  }
};

const removeBusinessAvatar = () => {
  temporary.config.business_info.avatar = ''
}

/// Business Info - End
///////////////////////////


</script>
