<template>
  <div v-if="loading" class="loading-overlay">
    <div class="dot"></div>
    <span class="text">
      Loading
    </span>
  </div>

  <div v-else>
    <AdminHeader />

    <div class="breadcrumb-main px-4">
      <h4 class="text-capitalize breadcrumb-title">Thông tin</h4>
      <div class="breadcrumb-action justify-content-center flex-wrap">
         <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
               <li class="breadcrumb-item"><a href="/dashboard"><i class="uil uil-estate"></i>Trang chủ</a></li>
               <li class="breadcrumb-item active" aria-current="page">Thông tin</li>
            </ol>
         </nav>
      </div>
    </div>

    <main class="main-content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">

            <div class="row">
              <div class="col-lg-6 card">
                <div class="dm-button-list d-flex flex-wrap">
                  <button 
                    class="btn btn-primary btn-default btn-squared btn-shadow-primary py-3 px-4"
                    type="button"
                    disabled: disabled
                    >
                    Thông tin liên hệ
                  </button>
                  <div class="position-absolute" style="top: 0; right: 0;">
                    <button
                      type="submit"
                      @click="handleSave"
                      class="btn btn-success btn-default btn-squared px-3 py-2">
                      Lưu
                    </button>
                  </div>
                </div>

                <div class="py-3 px-3">
                  <form>
                    <div class="form-group row">
                      <div class="col-sm-2 d-flex aling-items-center">
                          <label class=" col-form-label color-dark fs-14 fw-500 align-center">Thương hiệu</label>
                      </div>
                      <div class="col-sm-10">
                        <input
                            v-model="temporary.org_info.brand_name"
                            type="text"
                            class="form-control ih-medium ip-light radius-xs b-light py-1" 
                          >
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="col-sm-2 d-flex aling-items-center">
                        <label class=" col-form-label color-dark fs-14 fw-500 align-center">Địa chỉ</label>
                      </div>
                      <div class="col-sm-10">
                        <input
                            v-model="temporary.org_info.address"
                            type="text"
                            class="form-control ih-medium ip-light radius-xs b-light py-1" 
                          >
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-sm-2 d-flex aling-items-center">
                          <label class=" col-form-label color-dark fs-14 fw-500 align-center">Điện thoại</label>
                        </div>
                        <div class="col-sm-10">
                          <input
                              v-model="temporary.org_info.phone"
                              type="text"
                              class="form-control ih-medium ip-light radius-xs b-light py-1" 
                            >
                        </div>
                    </div>

                    <div class="form-group row">
                      <div class="col-sm-2 d-flex aling-items-center">
                        <label class=" col-form-label color-dark fs-14 fw-500 align-center">Facebook</label>
                      </div>
                      <div class="col-sm-10">
                        <input
                            v-model="temporary.org_info.facebook"
                            type="text"
                            class="form-control ih-medium ip-light radius-xs b-light py-1" 
                          >
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="col-sm-2 d-flex aling-items-center">
                        <label class=" col-form-label color-dark fs-14 fw-500 align-center">TikTok</label>
                      </div>
                      <div class="col-sm-10">
                        <input
                            v-model="temporary.org_info.tiktok"
                            type="text"
                            class="form-control ih-medium ip-light radius-xs b-light py-1" 
                          >
                      </div>
                    </div>

                    <div class="form-group row">
                      <div class="col-sm-2 d-flex aling-items-center">
                        <label class=" col-form-label color-dark fs-14 fw-500 align-center">Email</label>
                      </div>
                      <div class="col-sm-10">
                        <input
                            v-model="temporary.org_info.email"
                            type="email"
                            class="form-control ih-medium ip-light radius-xs b-light py-1" 
                          >
                      </div>
                    </div>
                  
                </form>
                </div>
              </div>

              <!-- <div class="col-lg-6 card">
                <div class="dm-button-list d-flex flex-wrap">
                  <button 
                    class="btn btn-primary btn-default btn-squared btn-shadow-primary py-3 px-4"
                    type="button"
                    disabled: disabled
                    >
                    Chọn giao diện
                  </button>
                  <div class="position-absolute" style="top: 0; right: 0;">
                    <button
                      type="submit"
                      @click="handleSave"
                      class="btn btn-success btn-default btn-squared px-3 py-2">
                      Lưu
                    </button>
                  </div>
                </div>

                <div class="row">
                  <div class="col-6 mb-30">
                     <div class="card h-100">
                        <div class="card-body p-30">
                           <div class="pricing d-flex align-items-center">
                              <span class="pricing__tag color-secondary order-bg-opacity-secondary rounded-pill">
                                Giao diện mặc định
                              </span>
                           </div>
                           <div class="pricing__price rounded">
                              <p class="pricing_value display-3 color-dark d-flex align-items-center text-capitalize fw-600 mb-1">
                                 <sup>$</sup>39
                                 <small class="pricing_user">Per month</small>
                              </p>
                              <p class="pricing_subtitle mb-0">For 10 Users</p>
                           </div>
                           <div class="pricing__features">
                              <ul>
                                 <li><span class="fa fa-check"></span>500GB+ file space</li>
                                 <li><span class="fa fa-check"></span>Unlimited projects</li>
                                 <li><span class="fa fa-check"></span>Unlimited boards</li>
                                 <li><span class="fa fa-check"></span>Basic project management</li>
                                 <li><span class="fa fa-check"></span>Custom Post Types</li>
                                 <li><span class="fa fa-check"></span>Subtasks</li>
                              </ul>
                           </div>
                        </div>
                        <div class="price_action d-flex pb-30 ps-30">
                           <label class="d-flex align-items-center">
                              
                              <span class="text-capitalize">Chọn mặc định</span>
                           </label>
                        </div>
                     </div>
                  </div>
                  <div class="col-6 mb-30">
                     <div class="card h-100">
                        <div class="card-body p-30">
                           <div class="pricing d-flex align-items-center">
                              <span class="pricing__tag color-success order-bg-opacity-success rounded-pill">
                                Giao diện Khách Sạn
                              </span>
                           </div>
                           <div class="pricing__price rounded">
                              <p class="pricing_value display-3 color-dark d-flex align-items-center text-capitalize fw-600 mb-1">
                                 <sup>$</sup>79
                                 <small class="pricing_user">Per month</small>
                              </p>
                              <p class="pricing_subtitle mb-0">For 50 Users</p>
                           </div>
                           <div class="pricing__features">
                              <ul>
                                 <li><span class="fa fa-check"></span>500GB+ file space</li>
                                 <li><span class="fa fa-check"></span>Unlimited projects</li>
                                 <li><span class="fa fa-check"></span>Unlimited boards</li>
                                 <li><span class="fa fa-check"></span>Basic project management</li>
                                 <li><span class="fa fa-check"></span>Custom Post Types</li>
                                 <li><span class="fa fa-check"></span>Subtasks</li>
                              </ul>
                           </div>
                        </div>
                        <div class="price_action d-flex pb-30 ps-30">
                           <label class="d-flex align-items-center">
                              
                              <span class="text-capitalize">Chọn Hotels</span>
                           </label>
                        </div>
                     </div>
                  </div>
                </div>
                
              </div> -->
            </div>

          </div>
        </div>
      </div>
    </main>  

  </div>
</template>

<script setup>
import { ref, reactive, onMounted } from 'vue';
import AdminHeader from '../../components/AdminHeader.vue';
import { checkAdminThemeId } from '../../utils/themeUtils.js';
import apiResource from '../../composables/apiResource.js';
import { sendMessage } from '../../utils/messageUtils.js';
const {
  admin_get_org_info,
  admin_update_org_info
} = apiResource();

const loading = ref(false);

const temporary = reactive({
  org_info: {
    brand_name: '',
    address: '',
    phone: '',
    facebook: '',
    tiktok: '',
    email: ''
  }
});

const handleSave = async () => {
  const orgInfoSaved = await admin_update_org_info({
    org_info: temporary.org_info
  });

  if (orgInfoSaved.status == 400) {
    sendMessage('Lưu không thành công', 'danger', 'sun-o')
  } else {
    temporary.org_info = orgInfoSaved.data.org_info;
    sendMessage('Lưu thành công', 'success', 'check-circle')
  }
}

onMounted(async() => {
  loading.value = true;
  const data = await admin_get_org_info();

  if (data) {
    if (data.status == 400) {
      sendMessage('Tải dữ liệu Information lỗi, vui lòng liên hệ admin', 'danger', 'sun-o')
    } else {
      temporary.org_info = data.org_info

      await checkAdminThemeId();
      loading.value = false;
    }
  } 
})


///////////////////////////
/// Theme Block - Start

/// Theme Block - End
///////////////////////////


</script>
