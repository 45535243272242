const msgButtons = document.querySelectorAll('.btn-message');
  let messageCount = 0;
  function createMessage(messageText,type,icon,loading,duration){
    let message = ``;
    console.log(icon);
    const messageShowcase = $('.message-wrapper');
    if(!loading){
      message = `
        <div class="dm-pop-message message-${type} message-${messageCount}">
            <span class="dm-pop-message__icon">
                <i class="la la-${icon}"></i>
            </span>
            <span class="dm-pop-message__text"><p>${messageText}</p></span>
        </div>
      `;
    }else{
      message = `
        <div class="dm-pop-message message-${type} message-${messageCount}">
            <span class="dm-pop-message__icon">
                <i class="la la-loader"></i>
            </span>
            <span class="dm-pop-message__text"><p>${messageText}</p></span>
        </div>
    `;
    }
    if(duration){
        message = `
        <div class="dm-pop-message message-${type} message-${messageCount}">
            <span class="dm-pop-message__icon">
                <i class="la la-${icon}"></i>
            </span>
            <span class="dm-pop-message__text"><p>${messageText}</p></span>
        </div>
    `;
    }
    
    messageShowcase.append(message);
    messageCount++;
  }
  function showMessage(e) {
    e.preventDefault();
  
    // Define the duration function to handle default values
    let getDuration = (optionValue, defaultValue) =>
      typeof optionValue === "undefined" ? defaultValue : optionValue;
  
    // Retrieve data attributes
    let messageText = this.textContent;
    let duration = this.dataset.messageduration;
    let messageType = this.dataset.messagetype;
    let messageIcon = this.dataset.messageicon;
    let messageLoading = this.dataset.messageLoading;
    
    // Use the duration function to set a default value if duration is not defined
    let messageDuration = getDuration(duration, 3000);
  
    // Create the message
    createMessage(messageText, messageType, messageIcon, messageLoading, messageDuration);
  
    let thismessage = messageCount - 1;
  
    // Use setTimeout to remove the message after the specified duration
    setTimeout(function() {
      $(document).find(".message-" + thismessage).remove();
    }, messageDuration);
  }

  if(msgButtons){
    msgButtons.forEach(messageButton => messageButton.addEventListener('click',showMessage));
  }