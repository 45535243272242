import { ref } from 'vue';
const themeLoaded = ref(false);

export const checkAdminThemeId = async () => {

  // For theme_id_3

  // const loadScript = (src, isModule = false) => {
  //   return new Promise((resolve, reject) => {
  //     const script = document.createElement('script');
  //     script.src = src;
  //     script.type = isModule ? 'module' : 'text/javascript';
  //     script.async = false; // Ensure the script runs in order
  //     script.onload = resolve;
  //     script.onerror = reject;
  //     document.body.appendChild(script);
  //   });
  // };

  // const scripts = [
  //   '/src/assets/theme_admin_id_3/js/jquery.min.js',
  //   '/src/assets/theme_admin_id_3/js/bootstrap.min.js',
  //   '/src/assets/theme_admin_id_3/js/bootstrap-select.min.js',
  //   '/src/assets/theme_admin_id_3/js/zoom.js',
  //   '/src/assets/theme_admin_id_3/js/morris.min.js',
  //   '/src/assets/theme_admin_id_3/js/raphael.min.js',
  //   '/src/assets/theme_admin_id_3/js/morris.js',
  //   '/src/assets/theme_admin_id_3/js/jvectormap.min.js',
  //   '/src/assets/theme_admin_id_3/js/jvectormap-us-lcc.js',
  //   '/src/assets/theme_admin_id_3/js/jvectormap-data.js',
  //   '/src/assets/theme_admin_id_3/js/jvectormap.js',
  //   '/src/assets/theme_admin_id_3/js/apexcharts/apexcharts.js',
  //   '/src/assets/theme_admin_id_3/js/apexcharts/line-chart-1.js',
  //   '/src/assets/theme_admin_id_3/js/apexcharts/line-chart-2.js',
  //   '/src/assets/theme_admin_id_3/js/apexcharts/line-chart-3.js',
  //   '/src/assets/theme_admin_id_3/js/apexcharts/line-chart-4.js',
  //   '/src/assets/theme_admin_id_3/js/apexcharts/line-chart-5.js',
  //   '/src/assets/theme_admin_id_3/js/apexcharts/line-chart-6.js',
  //   '/src/assets/theme_admin_id_3/js/apexcharts/line-chart-7.js',
  //   '/src/assets/theme_admin_id_3/js/switcher.js',
  //   '/src/assets/theme_admin_id_3/js/theme-settings.js',
  //   '/src/assets/theme_admin_id_3/js/main.js'
  // ];

  // Promise.all(scripts.map(src => loadScript(src)))
  //   .then(() => {
  //     console.log('All scripts loaded successfully');
  //     // Initialize your charts or zoom after all scripts are loaded
  //   })
  //   .catch(error => {
  //     console.error('Error loading scripts', error);
  //   });

  if (!themeLoaded.value) {
    themeLoaded.value = true;
  }
};

export default themeLoaded;
