import usePageRequests from './usePageRequests.js';
import { useLogout } from '../utils/logout.js';

const apiUrl = import.meta.env.VITE_API_URL;
const errorStatus = 'error';

export default function apiResource() {
    const { kickLogout } = useLogout();
    const { makeRequest, makePostRequest, uploadFile } = usePageRequests();

    ////////////////////////////////////////////////
    ///// Admin Time Generation Page
    /////
    const admin_get_schedule = async () => {
        try {
            const response = await makeRequest(`${apiUrl}/admin/schedule`);
            if (response.status === errorStatus) { kickLogout() }
            return response;
        } catch (error) {
            console.error('Error fetching:', error);
            return null;
        }
    }

    const admin_new_schedule = async (data) => {
        try {
            const response = await makePostRequest(`${apiUrl}/admin/schedule/initschedule`, data);
            if (response.status === 'error') { kickLogout() }
            return response;
        } catch (error) {
            console.error('Error saving:', error);
            return null;
        }
    }

    const admin_update_schedule = async (data) => {
        try {
            const response = await makePostRequest(`${apiUrl}/admin/schedule/schedules`, data);
            if (response.status === errorStatus) { kickLogout() }
            return response;
        } catch (error) {
            console.error('Error fetching:', error);
            return null;
        }
    }

    ////////////////////////////////////////////////
    ///// Admin Schedule Page
    /////
    const admin_get_calendar = async () => {
        try {
            const response = await makeRequest(`${apiUrl}/admin/calendar`);
            if (response.status === errorStatus) { kickLogout() }
            return response;
        } catch (error) {
            console.error('Error fetching:', error);
            return null;
        }
    }

    const admin_get_order = async (data) => {
        try {
            let url = new URL(`${apiUrl}/admin/order`); // Use the URL constructor
            Object.keys(data).forEach(key => url.searchParams.append(key, data[key]));

            const response = await makeRequest(url);
            if (response.status === errorStatus) { kickLogout() }
            return response;
        } catch (error) {
            console.error('Error fetching:', error);
            return null;
        }
    }

    const admin_new_order = async (data) => {
        try {
            const response = await makePostRequest(`${apiUrl}/admin/order`, data);
            if (response.status === errorStatus) { kickLogout() }
            return response;
        } catch (error) {
            console.error('Error fetching:', error);
            return null;
        }
    }

    const admin_del_order = async (data) => {
        try {
            const response = await makePostRequest(`${apiUrl}/admin/order/delete`, data);
            if (response.status === errorStatus) { kickLogout() }
            return response;
        } catch (error) {
            console.error('Error fetching:', error);
            return null;
        }
    }

    const admin_get_order_revenue = async (data) => {
        try {
            let url = new URL(`${apiUrl}/admin/order/revenue`);
            Object.keys(data).forEach(key => url.searchParams.append(key, data[key]));

            const response = await makeRequest(url);
            if (response.status === errorStatus) { kickLogout() }
            return response;
        } catch (error) {
            console.error('Error fetching:', error);
            return null;
        }
    }

    ////////////////////////////////////////////////
    ///// Admin Product Information START
    /////
    const admin_get_product = async (data) => {
        try {
            let url = new URL(`${apiUrl}/admin/schedule/get-product`);

            if (data) {
                Object.keys(data).forEach(key => url.searchParams.append(key, data[key]));
            }

            const response = await makeRequest(url);
            if (response.status === errorStatus) { kickLogout() }
            return response;
        } catch (error) {
            console.error('Error fetching:', error);
            return null;
        }
    }

    const admin_create_product = async (data) => {
        try {
            const response = await makePostRequest(`${apiUrl}/admin/schedule/create-product`, data);
            if (response.status === errorStatus) { kickLogout() }
            return response;
        } catch (error) {
            console.error('Error fetching:', error);
            return null;
        }
    }

    const admin_delete_product = async (data) => {
        try {
            const response = await makePostRequest(`${apiUrl}/admin/schedule/delete-product`, data);
            if (response.status === errorStatus) { kickLogout() }
            return response;
        } catch (error) {
            console.error('Error fetching:', error);
            return null;
        }
    }

    const admin_update_product = async (data) => {
        try {
            const response = await makePostRequest(`${apiUrl}/admin/schedule/update-product`, data);
            if (response.status === errorStatus) { kickLogout() }
            return response;
        } catch (error) {
            console.error('Error fetching:', error);
            return null;
        }
    }

    admin_update_product

    /////
    ///// Admin Product Information END
    ////////////////////////////////////////////////

    ////////////////////////////////////////////////
    ///// Admin Config Page
    /////
    const admin_get_config = async () => {
        try {
            const response = await makeRequest(`${apiUrl}/admin/config`);
            if (response.status === errorStatus) { kickLogout() }
            return response;
        } catch (error) {
            console.error('Error fetching:', error);
            return null;
        }
    }

    const admin_update_config = async (data) => {
        try {
            const response = await makePostRequest(`${apiUrl}/admin/config`, data);
            if (response.status === errorStatus) { kickLogout() }
            return response;
        } catch (error) {
            console.error('Error fetching:', error);
            return null;
        }
    }

    ////////////////////////////////////////////////
    ///// Admin Organization Information Page
    /////
    const admin_get_org_info = async () => {
        try {
            const response = await makeRequest(`${apiUrl}/admin/config/org-info`);
            if (response.status === errorStatus) { kickLogout() }
            return response;
        } catch (error) {
            console.error('Error fetching:', error);
            return null;
        }
    }

    const admin_update_org_info = async (data) => {
        try {
            const response = await makePostRequest(`${apiUrl}/admin/config/org-info`, data);
            if (response.status === errorStatus) { kickLogout() }
            return response;
        } catch (error) {
            console.error('Error fetching:', error);
            return null;
        }
    }

    const admin_change_password = async (data) => {
        try {
            const response = await makePostRequest(`${apiUrl}/admin/config/password`, data);
            if (response.status === errorStatus) { kickLogout() }
            return response;
        } catch (error) {
            console.error('Error fetching:', error);
            return null;
        }
    }

    ////////////////////////////////////////////////
    ///// Admin Order Page
    /////
    const admin_get_order_by_codelabel = async (data) => {
        try {
            let url = new URL(`${apiUrl}/admin/order/code-label`);
            Object.keys(data).forEach(key => url.searchParams.append(key, data[key]));

            const response = await makeRequest(url);
            if (response.status === errorStatus) { kickLogout() }
            return response;
        } catch (error) {
            console.error('Error fetching:', error);
            return null;
        }
    }

    ////////////////////////////////////////////////
    ///// Admin Referral - Create Account
    /////
    const admin_create_account = async (data) => {
        try {
            const response = await makePostRequest(`${apiUrl}/admin/account`, data);
            if (response.status === errorStatus) { kickLogout() }
            return response;
        } catch (error) {
            console.error('Error fetching:', error);
            return null;
        }
    }

    ////////////////////////////////////////////////
    ///// Public Login Page
    /////
    const auth_login = async (data) => {
        try {
            const response = await makePostRequest(`${apiUrl}/auth/login`, data);
            // if (response.status === errorStatus) { kickLogout() }
            return response;
        } catch (error) {
            console.error('Error fetching:', error);
            return null;
        }
    }

    ////////////////////////////////////////////////
    ///// Public Main Page
    /////
    const public_create_queue = async (data) => {
        try {
            const headers = {
                'x-organization': localStorage.getItem('organization') || 'main'
            };
            const response = await makePostRequest(`${apiUrl}/queue`, data, headers);
            return response;
        } catch (error) {
            console.error('Error fetching queue:', error);
            return null;
        }
    }

    const public_get_calendar = async () => {
        try {
            const headers = {
                'x-organization': localStorage.getItem('organization') || 'main'
            };
            const response = await makeRequest(`${apiUrl}/calendar`, headers);
            if (response.status === errorStatus) { kickLogout() }
            return response;
        } catch (error) {
            console.error('Error fetching:', error);
            return null;
        }
    }

    const public_get_organization = async () => {
        try {
            const response = await makeRequest(`${apiUrl}/public/orgs`);
            if (response.status === errorStatus) { kickLogout() }
            return response;
        } catch (error) {
            console.error('Error fetching:', error);
            return null;
        }
    }

    const public_upload_file = async (data) => {
        try {
            const response = await uploadFile(`${apiUrl}/public/upload`, data);
            if (response.status === errorStatus) { kickLogout() }
            return response;
        } catch (error) {
            console.error('Error fetching:', error);
            return null;
        }
    }

    return {
        ///// Time Generation Page
        admin_get_schedule,
        admin_new_schedule,
        admin_update_schedule,

        ///// Admin Schedule Page
        admin_get_calendar,
        admin_get_order,
        admin_new_order,
        admin_del_order,
        admin_get_order_revenue,

        ///// Admin Schedule Page
        //    Product Information
        admin_get_product,
        admin_create_product,
        admin_delete_product,
        admin_update_product,

        ///// Admin Config Page
        admin_get_config,
        admin_update_config,
        
        ///// Admin Organization Information Page
        admin_get_org_info,
        admin_update_org_info,

        ///// Admin Privacy
        admin_change_password,

        ///// Admin Order Page
        admin_get_order_by_codelabel,

        ///// Admin Referral - Create Account
        admin_create_account,

        ///// Public Login Page
        auth_login,

        ///// Public Main Page
        public_create_queue,
        public_get_calendar,
        public_get_organization,
        public_upload_file,
    }
}