import { createApp } from "vue";
import App from "./App.vue";

import Vueform from '@vueform/vueform'
import vueformConfig from './../vueform.config'

import router from "./router";
import "./assets/loading.css";

import $ from 'jquery'; // Import jQuery
window.$ = $;          // Set jQuery to the global scope
window.jQuery = $;     // Set jQuery to the global scope (if needed)
  
createApp(App).use(Vueform, vueformConfig).use(router).mount("#app");
