import {reactive, computed} from 'vue'
const activeRequests = reactive([])
export default function usePageRequests(){
  const accessToken = localStorage.getItem('access_token');

  const isLoading = computed(()=> !!activeRequests.length)
  const makeRequest = async (url, headers = {}) =>{
    // push the url to the activeRequests
    const index = activeRequests.length
    activeRequests[index] = url

    const options = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        ...headers
      },
    };

    try {
      const response = await fetch(url, options)
        // .catch(error => alert(error)) // if failed remove the url from the activeRequests and alert error
      
      if (!response.ok) {
        const errorData = await response.json();
        if (errorData.statusCode === 'INTERNAL_SERVER'
          || errorData.message === 'UnauthorizedException'
        ) {
          return {
            status: 'error'
          }
        }
        
        return {
          status: response.status,
          statusText: response.statusText,
          error: errorData.error || response.error || 0,
          message: errorData.message || response.message || '',
          type: errorData.type || response.type || '',
          details: errorData.details || response.details || '',
        }
      }

      const data = await response.json()

      // // Check if the token is expired or unauthorized
      // if (response.status === 401 || data.message === 'UnauthorizedException') {
      //   return {
      //     status: 'error'
      //   } // Call auto-logout function
      // }

      // remove the url from activeRequests
      activeRequests.splice(index, 1)
      return data
    } catch (err) {
      activeRequests.splice(index, 1); // Remove the URL from activeRequests in case of error
      alert('Error occurred: ' + err); // Alert the error
      return null;
    }
  }

  const makePostRequest = async (url, body, headers = {}) => {
    const accessToken = localStorage.getItem('access_token');

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
        ...headers // Merge any additional headers
      },
      body: JSON.stringify(body)
    };
    try {
      const response = await fetch(url, options);

      if (!response.ok) {
        const errorData = await response.json();

        // Check if the token is expired or unauthorized
        if (errorData.statusCode === 'INTERNAL_SERVER'
          || errorData.message === 'UnauthorizedException'
        ) {
          return {
            status: 'error'
          } // Call auto-logout function
        }

        return {
          status: errorData.status || response.status,
          statusText: errorData.message || response.statusText,
          error: errorData.error || response.error || 0,
          message: errorData.message || response.message || '',
          type: errorData.type || response.type || '',
          details: errorData.details || response.details || '',
        }
        // const errorResponse = await response.json();
        // throw new Error(errorResponse.details || `HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      return data;
    } catch (error) {
      alert(error.message); // Alert the error message
      return null; // Return null or handle as needed
    }
    // return makeRequest(url, options);
  };

  const uploadFile = async (url, file, headers = {}) => {
    const accessToken = localStorage.getItem('access_token');
  
    const formData = new FormData();
    formData.append('file', file); // Append file to FormData
  
    const options = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        ...headers, // Merge any additional headers
      },
      body: formData, // Send FormData instead of JSON
    };
  
    try {
      const response = await fetch(url, options);
  
      if (!response.ok) {
        const errorData = await response.json();
        return {
          status: errorData.status || response.status,
          statusText: errorData.message || response.statusText,
        };
      }
  
      const result = await response.json();
      return result; // Return success result
    } catch (error) {
      console.error('File upload error:', error.message);
      return null; // Handle the error
    }
  };

  return { isLoading, makeRequest, makePostRequest, uploadFile }
}