<template>
  <div v-if="loading" class="loading-overlay">
    <div class="dot"></div>
    <span class="text">
      Loading
    </span>
  </div>

  <div v-else>
    <AdminHeader />

    <div class="breadcrumb-main px-4">
      <h4 class="text-capitalize breadcrumb-title">Đơn hàng</h4>
      <div class="breadcrumb-action justify-content-center flex-wrap">
         <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
               <li class="breadcrumb-item"><a href="/dashboard"><i class="uil uil-estate"></i>Trang chủ</a></li>
               <li class="breadcrumb-item active" aria-current="page">Đơn hàng</li>
            </ol>
         </nav>
      </div>
    </div>

    <main class="main-content">
      <div class="container-fluid">
        <div class="col-lg-12">
          <div class="row">
            <div class="col-lg-12">
              <div class="card border-0 card-body">

                <div class="horizontal-form">
                  <form @submit.prevent="handleSearch">
                    <div class="row d-flex align-items-center">
                      <div class="col-sm-3 d-flex align-items-center">
                        <label class="col-form-label color-dark fs-14 fw-500">Mã đơn hàng</label>
                      </div>
                      <div class="col-sm-6">
                        <input
                          type="text"
                          class="form-control ih-medium ip-light radius-xs b-light py-1"
                          v-model="temporary.search.code_label">
                      </div>
                      <div class="col-sm-3 d-flex">
                        <button
                          type="submit"
                          class="btn btn-success btn-default btn-squared px-3 py-2">
                          Tìm kiếm
                        </button>
                      </div>
                    </div>
                  </form>

               </div>

              </div>
            </div>




            <div class="card" v-if="temporary.orders">

              <div class="dm-collapse" v-for="(o, odx) in temporary.orders" :key="odx">
                <div class="dm-collapse-item">
                  <div class="dm-collapse-item__header">
                    <a 
                      class="item-link" 
                      data-bs-toggle="collapse" 
                      data-bs-target="#collapse-body-1" 
                      aria-expanded="false"
                      @click="toggleIcon(odx)"
                    >
                      <i :class="{'la la-angle-right': !isOpen[odx], 'la la-angle-down': isOpen[odx]}"></i>
                      <h6>{{ o.code_label }}</h6>
                    </a>
                  </div>
                  <div v-show="isOpen[odx]" class="dm-collapse-item__body">
                    <div class="collapse-body-text">

                      <div class="col-lg-12">
                        <div class="card card-Vertical card-default card-md mb-4">
                           <div class="card-body py-md-30">
                              <form>
                                 <div class="row">
                                    <div class="col-md-6">
                                      <label class="color-dark fs-14 fw-500 align-center mb-10">Tên khách hàng</label>
                                      <input type="text" class="form-control ih-medium ip-gray radius-xs b-light px-15"
                                        v-model="o.slot_info.customer.name"
                                        :disabled="o.order"
                                      >
          
                                      <label class="color-dark fs-14 fw-500 align-center mb-10">Email</label>
                                      <input type="email" class="form-control ih-medium ip-gray radius-xs b-light px-15"
                                        v-model="o.slot_info.customer.email"
                                        :disabled="o.order"
                                      >
          
                                      <label class="color-dark fs-14 fw-500 align-center mb-10">Điện thoại</label>
                                      <input type="phone" class="form-control ih-medium ip-gray radius-xs b-light px-15"
                                        v-model="o.slot_info.customer.phone"
                                        :disabled="o.order"
                                      >
                                    </div>
          
                                    <div class="col-md-6" v-if="o.slot_choosing && !o.order">
                                      <div class="dm-button-list d-flex flex-wrap">
                                        <button
                                          v-for="(slot, sc) in o.slot_choosing" :key="sc"
                                          class="btn btn-default btn-squared color-primary btn-outline-primary"
                                          disabled
                                        >
                                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="svg replaced-svg">
                                            <polygon points="12 2 2 7 12 12 22 7 12 2"></polygon>
                                            <polyline points="2 17 12 22 22 17"></polyline>
                                            <polyline points="2 12 12 17 22 12"></polyline>
                                          </svg>
                                          {{ formattedDateOnly(slot.date) }} <br>
                                          {{ formatTime(slot.start) }} - {{ formatTime(slot.end) }} <br>
                                          {{ formatMoney(slot.price) }} <br>
                                        </button>
                                      </div>
                                    </div>
          
                                    <div class="col-md-6">
                                      
                                      <div class="row">
                                        <div class="cus-xl-12 col-md-12">
                                          <div class="card order-summery p-sm-25 p-15 order-summery--width mt-lg-0 mt-20">
                                             
                                             <div class="card-body px-sm-25 px-20">
                                                <div class="total">
                                                  <div>
                                                    <p>{{ o.code_label }}</p>
                                                  </div>

                                                  <div>
                                                    <p class="my-sm-0 my-2 media-badge text-uppercase">Mã giới thiệu</p>
                                                    <span>{{ o.slot_info.customer.ref_code }}</span>
                                                  </div>

                                                   <div>
                                                      <p class="my-sm-0 my-2 media-badge text-uppercase text-danger">Tổng giá</p>
                                                      <span>{{ formatMoney(o.product_info.total_price) }}</span>
                                                   </div>
                                                </div>
                                             </div>
                                          </div><!-- End: .order-summery-->
                                        </div>
          
                                        
                                      </div>
                                      
                                    </div>
          
                                    <div class="col-md-12 py-2">
                                      <div class="dm-button-list d-flex flex-wrap">
                                        <button
                                          v-for="(slot, idx) in o.product_info.products" :key="idx"
                                          class="btn btn-default btn-squared color-primary btn-outline-primary"
                                          disabled
                                        >
                                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="svg replaced-svg">
                                            <polygon points="12 2 2 7 12 12 22 7 12 2"></polygon>
                                            <polyline points="2 17 12 22 22 17"></polyline>
                                            <polyline points="2 12 12 17 22 12"></polyline>
                                          </svg>
                                          {{ slot.name }} <br>
                                          {{ `${slot.day}/${slot.month}/${slot.year}` }} <br>
                                          {{ slot.time_start }} - {{ slot.time_end }} <br>
                                        </button>
                                      </div>
                                    </div>
                                 </div>
                              </form>
                           </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>

            </div>




            <div class="col-lg-12" v-if="temporary.order">
              <div class="card card-Vertical card-default card-md mb-4">
                 <div class="card-header">
                    <h6>Thông tin đơn hàng </h6>
                 </div>
                 <div class="card-body py-md-30">
                    <form>
                       <div class="row">
                          <div class="col-md-6">
                            <label class="color-dark fs-14 fw-500 align-center mb-10">Tên khách hàng</label>
                            <input type="text" class="form-control ih-medium ip-gray radius-xs b-light px-15"
                              v-model="temporary.customer.name"
                              :disabled="temporary.order"
                            >

                            <label class="color-dark fs-14 fw-500 align-center mb-10">Email</label>
                            <input type="email" class="form-control ih-medium ip-gray radius-xs b-light px-15"
                              v-model="temporary.customer.email"
                              :disabled="temporary.order"
                            >

                            <label class="color-dark fs-14 fw-500 align-center mb-10">Điện thoại</label>
                            <input type="phone" class="form-control ih-medium ip-gray radius-xs b-light px-15"
                              v-model="temporary.customer.phone"
                              :disabled="temporary.order"
                            >
                          </div>

                          <div class="col-md-6" v-if="temporary.slot_choosing && !temporary.order">
                            <div class="dm-button-list d-flex flex-wrap">
                              <button
                                v-for="(slot, sc) in temporary.slot_choosing" :key="sc"
                                class="btn btn-default btn-squared color-primary btn-outline-primary"
                                disabled
                              >
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="svg replaced-svg">
                                  <polygon points="12 2 2 7 12 12 22 7 12 2"></polygon>
                                  <polyline points="2 17 12 22 22 17"></polyline>
                                  <polyline points="2 12 12 17 22 12"></polyline>
                                </svg>
                                {{ formattedDateOnly(slot.date) }} <br>
                                {{ formatTime(slot.start) }} - {{ formatTime(slot.end) }} <br>
                                {{ formatMoney(slot.price) }} <br>
                              </button>
                            </div>
                          </div>

                          <div class="col-md-6">
                            
                            <div class="row" v-if="temporary.order">
                              <div class="cus-xl-12 col-md-12">
                                <div class="card order-summery p-sm-25 p-15 order-summery--width mt-lg-0 mt-20">
                                   
                                   <div class="card-body px-sm-25 px-20">
                                      <div class="total">
                                        <div>
                                          <p>{{ temporary.order.code_label }}</p>
                                        </div>
                                        <div>
                                          <p class="my-sm-0 my-2 media-badge text-uppercase color-white bg-danger">Tổng giá</p>
                                          <span>{{ formatMoney(temporary.order.product_info.total_price) }}</span>
                                        </div>
                                      </div>
                                   </div>
                                </div><!-- End: .order-summery-->
                              </div>

                              
                            </div>
                            
                          </div>

                          <div class="col-md-12 py-2" v-if="temporary.order && temporary.order.product_info && temporary.order.product_info.products">
                            <div class="dm-button-list d-flex flex-wrap">
                              <button
                                v-for="(slot, idx) in temporary.order.product_info.products" :key="idx"
                                class="btn btn-default btn-squared color-primary btn-outline-primary"
                                disabled
                              >
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="svg replaced-svg">
                                  <polygon points="12 2 2 7 12 12 22 7 12 2"></polygon>
                                  <polyline points="2 17 12 22 22 17"></polyline>
                                  <polyline points="2 12 12 17 22 12"></polyline>
                                </svg>
                                {{ slot.name }} <br>
                                {{ `${slot.day}/${slot.month}/${slot.year}` }} <br>
                                {{ slot.time_start }} - {{ slot.time_end }} <br>
                              </button>
                            </div>
                          </div>

                          <div class="col-md-6">
                             <div class="layout-button mt-0">

                                <button @click="handleReset"
                                  type="button" class="btn btn-primary btn-default btn-squared px-30">
                                  Trở lại
                                </button>
                             </div>
                          </div>
                       </div>
                    </form>
                 </div>
              </div>
            </div>


           
          </div>
        </div>
      </div>
    </main>

  </div>
</template>

<script setup>
import { ref, reactive, onMounted } from 'vue';
import AdminHeader from './../../components/AdminHeader.vue';
import { checkAdminThemeId } from './../../utils/themeUtils.js';
import apiResource from '../../composables/apiResource.js';
import { sendMessage } from './../../utils/messageUtils';

const {
  admin_get_order_by_codelabel,
} = apiResource();

const loading = ref(false);
const isOpen = ref({});
const toggleIcon = (index) => {
  isOpen.value[index] = !isOpen.value[index];
};


const temporary = reactive({
  search: {
    code_label: ''
  },
  orders: [],
  order: null,
  customer: {
    is_show: false,
    name: '',
    email: '',
    phone: ''
  }
});

const handleReset = async () => {
  temporary.search.code_label = '';
  temporary.order = null;
  temporary.customer = {
    is_show: false,
    name: '',
    email: '',
    phone: ''
  }
}

const formatMoney = (number) => {
  return new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(number);
}

const handleSearch = async () => {
  const order_searched = await admin_get_order_by_codelabel(temporary.search);

  if (order_searched) {
    temporary.orders = order_searched;
    // temporary.customer = { ...temporary.order.slot_info.customer }
  } else {
    sendMessage('Không tìm thấy đơn hàng', 'danger', 'sun-o')
  }
}

onMounted(async() => {
    loading.value = true;
    await checkAdminThemeId();
    loading.value = false;
})

</script>
