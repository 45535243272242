<template>
  <div v-if="loading" class="loading-overlay">
    <div class="dot"></div>
    <span class="text">
      Loading
    </span>
  </div>

  <div v-else>
    <AdminHeader />

    <div class="breadcrumb-main px-4">
      <h4 class="text-capitalize breadcrumb-title">Lịch đặt</h4>
      <div class="breadcrumb-action justify-content-center flex-wrap">
         <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
               <li class="breadcrumb-item"><a href="/dashboard"><i class="uil uil-estate"></i>Trang chủ</a></li>
               <li class="breadcrumb-item active" aria-current="page">Lịch đặt</li>
            </ol>
         </nav>
      </div>
   </div>

    <SchedulerCom_2 
      :temporary="temporary"
      :posts="posts"
      :formatMoney="formatMoney"
      :formattedDate="formattedDate"
      :formattedDateOnly="formattedDateOnly"
      :formatTime="formatTime"
      :toggleChoose="toggleChoose"

      :handleAdminBookTimeSlot="handleAdminBookTimeSlot"
      :handleOrderInfoCancel="handleOrderInfoCancel"
      :handleOrderInfoDelete="handleOrderInfoDelete"
    />
  </div>
</template>
<script setup>
import { ref, reactive, onMounted } from 'vue';

import AdminHeader from './../../components/AdminHeader.vue';
import SchedulerCom_2 from './theme_id_2/SchedulerCom.vue';
import { checkAdminThemeId } from './../../utils/themeUtils.js';
import apiResource from '../../composables/apiResource.js';

const {
  admin_get_calendar,
  admin_new_order,
  admin_get_order,
  admin_del_order,
  admin_get_order_revenue,
} = apiResource();

const theme_id = ref(localStorage.getItem('theme_admin_id'));
const loading = ref(false);
const posts = ref({});
const mergedPosts = ref([]);

let testob = reactive({
  text: ''
})

const temporary = reactive({
  tab_active: 1,
  order: null,
  revenue: {
    system: 0,
    admin: 0
  },
  selectedDate: 0,
  total_price: 0,
  slot_choosing: [],
  customer: {
    is_show: false,
    name: '',
    email: '',
    phone: ''
  }
});

const resetTemporary = () => {
  temporary.order = null;
  temporary.selectedDate = 0;
  temporary.total_price = 0;
  temporary.slot_choosing = [];
  temporary.customer = {
    is_show: false,
    name: '',
    email: '',
    phone: ''
  };
};

const resetScheduleClient = () => {
  for (let i of posts.value.dates) {
    for (let j of i.courts) {
      for (let k of j.times) {
        k.is_choose = false;
      }
    }
  }
}

const handleAdminBookTimeSlot = async () => {
  try {
    const dataBooking = temporary.slot_choosing.map(({ schedule_id, date }) => ({
      schedule_id,
      date
    }))
    await admin_new_order({
      times: dataBooking,
      customer: temporary.customer
    })

    resetTemporary();
    loadSchedule();
    await handleLoadRevenue();
  } catch (error) {
    console.log('Error handleAdminBookTimeSlot button data:', error);
  }
};

const handleOrderInfoDelete = async (id) => {
  try {
    await admin_del_order({ id: id });
    loadSchedule();
    resetTemporary();
    await handleLoadRevenue();
  } catch (error) {
    console.log('Error getOrder button data:', error);
    return null;
  }
};








// Function to merge adjacent time slots for the same court
const mergeTimeSlots = (slots) => {
  if (!slots || slots.length === 0) return [];

  const merged = [];
  let currentSlot = { ...slots[0] };

  for (let i = 1; i < slots.length; i++) {
    const nextSlot = slots[i];

    // Check if the next slot is adjacent and has the same properties
    if (
      currentSlot.end === nextSlot.start &&
      currentSlot.price === nextSlot.price &&
      currentSlot.is_active === nextSlot.is_active
    ) {
      // Extend the current slot's end time to the next slot's end time
      currentSlot.end = nextSlot.end;
    } else {
      // Push the current merged slot and start a new one
      merged.push(currentSlot);
      currentSlot = { ...nextSlot };
    }
  }

  // Push the last slot
  merged.push(currentSlot);

  return merged;
};

const loadSchedule = async () => {
  posts.value = await admin_get_calendar();

  if (posts.value.dates) {
    mergedPosts.value = posts.value.dates.map((courtData) => {
      const courtName = Object.keys(courtData.courts);
      const mergedCourts = {};

      courtName.forEach((name) => {
        mergedCourts[name] = mergeTimeSlots(courtData.courts[name]);
      });

      return {
        ...courtData,
        courts: mergedCourts,
      };
    })
  }
}




const handleLoadRevenue = async () => {
  const data_revenue = await admin_get_order_revenue({ type: 'all'});
  temporary.revenue.admin = data_revenue.find(i => i.type === 0).price;
  temporary.revenue.system = data_revenue.find(i => i.type === 1).price;
};


function removeByScheduleId(schedules, scheduleId) {
  const index = schedules.findIndex(schedule => schedule.schedule_id === scheduleId);
  if (index !== -1) {
    schedules.splice(index, 1); // Remove the item at the found index
  } else {
    console.log('Item with schedule_id not found');
  }
}

const toggleChoose = async (slot) => {

  slot.is_choose = !slot.is_choose;

  temporary.total_price += slot.is_choose == true ? slot.price : (-1) * slot.price;

  if (slot.is_choose == true) {
    temporary.slot_choosing.push({
      ...slot
    });

    if (slot.action_by === '0' || slot.action_by === '1') {
      temporary.order = JSON.parse(JSON.stringify(await admin_get_order(slot)));
      temporary.customer = { ...temporary.order.slot_info.customer }
      return;
    }
  } else {
    removeByScheduleId(temporary.slot_choosing, slot.schedule_id);
  }

  if (temporary.slot_choosing.length < 1) {
    temporary.order = null;
  }
};

const handleOrderInfoCancel = () => {
  resetScheduleClient();
  resetTemporary();
}

onMounted(async() => {
  loading.value = true;
  await loadSchedule();
  await handleLoadRevenue();
  await checkAdminThemeId();
  loading.value = false;
})

const formatTime = (timeString) => {
  return new Date(timeString).toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit', hour12: false });
};

const formattedDateOnly = (dateInput) => {
  return new Intl.DateTimeFormat('vi-VN', {
    day: '2-digit',   // Two-digit day (e.g., 18)
    month: '2-digit', // Two-digit month (e.g., 09)
    year: 'numeric'   // Four-digit year (e.g., 2024)
  }).format(new Date(dateInput));
}


const formattedDate = (dateInput) => {
  return new Intl.DateTimeFormat('vi-VN', {
    weekday: 'long',  // Full day of the week (e.g., Thứ Năm)
    day: '2-digit',   // Two-digit day (e.g., 18)
    month: '2-digit', // Two-digit month (e.g., 09)
    year: 'numeric'   // Four-digit year (e.g., 2024)
  }).format(new Date(dateInput));
}

const formatMoney = (number) => {
    return new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(number);
}

</script>


<style scoped>
/* Optional chart container styling */
#hs-doughnut-chart {
  max-width: 100%;
  height: 230px;
}
</style>