import { reactive } from 'vue';

let messageCount = reactive({ count: 0 });

// type : 
// warning / success / danger (can customize more css)
const createMessage = (messageText, type, icon, loading, duration) => {
  let message = "";
  const messageShowcase = document.querySelector(".message-wrapper");

  if (!loading) {
    message = `
      <div class="dm-pop-message message-${type} message-${messageCount.count}">
        <span class="dm-pop-message__icon">
          <i class="la la-${icon}"></i>
        </span>
        <span class="dm-pop-message__text"><p>${messageText}</p></span>
      </div>
    `;
  } else {
    message = `
      <div class="dm-pop-message message-${type} message-${messageCount.count}">
        <span class="dm-pop-message__icon">
          <i class="la la-loader"></i>
        </span>
        <span class="dm-pop-message__text"><p>${messageText}</p></span>
      </div>
    `;
  }

  // Append message to the wrapper
  messageShowcase.insertAdjacentHTML('beforeend', message);

  const currentMessageCount = messageCount.count;
  messageCount.count++;

  setTimeout(() => {
    const messageElement = document.querySelector(`.message-${type}.message-${currentMessageCount}`);
    if (messageElement) {
      messageElement.remove();
    }
  }, duration);
};

const sendMessage = (messageText, type, icon, loading = false, duration = 3000) => {
  createMessage(messageText, type, icon, loading, duration);
};

export { sendMessage };
