import { createRouter, createWebHistory } from 'vue-router';
import TimeGenerate from '../views/admin/TimeGenerate.vue';
import Scheduler from '../views/admin/Scheduler.vue';
import Order from '../views/admin/Order.vue';
import Login from '../views/admin/Login.vue';
import Config from '../views/admin/Config.vue';
import CreateUser from '../views/admin/CreateUser.vue'
import Information from '../views/admin/Information.vue'
import Privacy from '../views/admin/Privacy.vue'

import { isAuthenticated, getCurrentUserRole } from '../auth/auth';

// Define routes
const routes = [
  { path: '', name: 'Main', component: Login },
  { path: '/login', name: 'Login', component: Login },

  // Example of restricted route (admin only)
  { path: '/dashboard', name: 'Dashboard', component: Scheduler,
    meta: { requiresAuth: true, roles: ['admin'] }
  },
  { path: '/scheduler', name: 'Scheduler', component: Scheduler,
    meta: { requiresAuth: true, roles: ['admin'] }
  },
  { path: '/times', name: 'TimeGenerate', component: TimeGenerate,
    meta: { requiresAuth: true, roles: ['admin'] }
  },
  { path: '/order', name: 'Order', component: Order,
    meta: { requiresAuth: true, roles: ['admin'] }
  },
  { path: '/config', name: 'Config', component: Config,
    meta: { requiresAuth: true, roles: ['admin'] }
  },
  { path: '/user', name: 'CreateUser', component: CreateUser,
    meta: { requiresAuth: true, roles: ['admin'] }
  },
  { path: '/information', name: 'Information', component: Information,
    meta: { requiresAuth: true, roles: ['admin'] }
  },
  { path: '/privacy', name: 'Privacy', component: Privacy,
    meta: { requiresAuth: true, roles: ['admin'] }
  },
];

// Create router instance
const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Navigation guard to check authentication and roles
router.beforeEach((to, from, next) => {
  const isAuth = isAuthenticated(); // Check if user is logged in
  const userRole = getCurrentUserRole(); // Get current user role

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!isAuth) {
      return next({ name: 'Login' }); // Redirect to login if not authenticated
    }

    if (to.meta.roles && !to.meta.roles.includes(userRole)) {
      return next({ name: 'Login' }); // Redirect if user lacks required role
    }
  }

  // Allow access if no auth/role restrictions
  next();
});

export default router;
